@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";
@import "../../../../lg5-common/front/pages/UTS/css/swiper-8.2.4.min.css";
// common
// BTOCSITE-6883 신규 WSG 적용 - 렌탈/케어
br.m {
    display:none;
    @include mobile {
        display:block;
    }
}
br.pc {
    display:block;
    @include mobile {
        display:none;
    }
}

.sub-main {
    padding-bottom: 133px;
    @include mobile{
        padding-bottom: 80px;
    }
}

.care-home-section {
    margin:100px auto 0;
    padding:0 20px;
    max-width:1420px;
    @include mobile {
        margin-top:58px;
        padding:0 16px;
    }
    .tit-wrap {
        margin-bottom: 32px;
        text-align: center;
        @include mobile {
            margin-bottom:19px;
            & + .tabs-wrap {
                padding-top: 0;
            }
        }
        .set-tit {
            font-size: 32px;
            line-height: 52px;
            @include mobile {
                font-size: 20px;
                line-height: 26px;
            }
        }
        .desc {
            margin-top: 8px;
            font-size:24px;
            line-height:30px;
            @include mobile {
                margin-top: 16px;
                font-size: 15px;
                line-height: 23px;
            }
        }
    }
    .tabs-wrap,
    .scroll-wrap {
        margin-bottom: 32px;
        @include mobile {
            margin-bottom: 20px;
            .tabs{
                >li{
                    >a{
                        border-width:1.5px;           
                    }
                }
            }
        }
    }
    &.care-recommended {
        margin-bottom:-8px;
        @include mobile{
            margin-bottom:-4px;
            padding:0 12px;
        }
        .tabs-wrap,
        .scroll-wrap {
            margin-bottom: 24px;
            text-align: center; //BTOCSITE-7335
            //BTOCSITE-7335
            .tabs {
                display: inline-block;
                li{
                    a{
                        @include mobile{
                            font-size:15px;
                            line-height:24px;
                        }
                    }
                }
            }
            @include mobile {
                margin-bottom: 8px;
            }
            &.border-type {
                margin-top:0;
                .tabs {
                    display: inline-block;
                    li {
                        margin-left:8px;
                        padding:0;
                        a {
                            position:relative;
                            padding:0 24px;
                            height:38px;
                            font-size:18px;
                            font-weight:700;
                            line-height:36px;
                            border:1px solid transparent;
                            @include mobile{
                                padding:0 10px;
                                height:32px;
                                font-size:14px;
                                line-height:30px;
                            }
                        }
                        &.on {
                            a{
                                border:1px solid #000;
                                border-radius:19px;
                            }
                        }
                        &:first-child{
                            margin-left:0;
                        }
                    }
                }
                &:before {
                    @include mobile{
                        display:block;
                    }
                }
                .scroll-controls{
                    display:none;
                }                
            }
            /* BTOCSITE-30118 [IDX182] 서브메인 관리 개편 건 s */
            .scroll-controls {
                display:none;
                /*
                .prev {
                    left: 0;
                    &:after {
                        content: "";
                        position: absolute;
                        top: -13px;
                        left: 0;
                        width: 100px;
                        height: 74px;
                        border-radius: 0;
                        box-shadow: none;
                        background-image: linear-gradient(to left, rgba(255,255,255,0), #fff 50%);
                        z-index: -1;
                    }
                }
                .next {
                    right: 0;
                    &:after {
                        content: "";
                        position: absolute;
                        top: -13px;
                        right: 0;
                        width: 100px;
                        height: 74px;
                        border-radius: 0;
                        box-shadow: none;
                        background-image: linear-gradient(to right, rgba(255,255,255,0), #fff 50%);
                        z-index: -1;
                    }
                }
                */
            }
            .ui_smooth_tab {
                overflow: hidden;
            }
            /* BTOCSITE-30118 [IDX182] 서브메인 관리 개편 건 e */            
        }
        //BTOCSITE-7335
        .tab-content {
            display: none;
        }
    }
    &.care-benefit {
        .tabs-wrap,
        .scroll-wrap {
            margin-bottom:0;
            //BTOCSITE-7335
            text-align: center;
            //BTOCSITE-7335
            .tabs {
                display: inline-block;
            }
        }
        //BTOCSITE-7335
        .tab-content {
            display: none;
        }
    }
}

// hero banner
.caresolution{
    .sub-main{
        .slide-wrap {
            &.hero-banner {
                .slide-content {
                    .slide-track  {
                        height:640px;
                        @include mobile{
                            height:370px;
                        }
                        .slide-conts {
                            position:relative;
                            height:640px;
                            @include mobile{
                                height:370px;
                            }
                            .slide-img {
                                overflow:hidden;
                                position: relative;
                                width:100%;
                                height:100%;
                                img, video {
                                    position:absolute;
                                    top:0;
                                    left:50%;
                                    transform:translateX(-50%);
                                    width: auto;
                                    height: auto;
                                    min-width: 100%;
                                    min-height: 100%;
                                    max-width:none;
                                    @media screen and (min-width:1920px) {
                                        width:100%;
                                        height:auto;
                                        top:50%;
                                        left:0;
                                        transform:translateY(-50%);
                                    }
                                    @include mobile {
                                        width:100%;
                                        height:auto;
                                        top:50%;
                                        left:0;
                                        transform:translateY(-50%);
                                    }
                                }
                                video{
                                    @include mobile {
                                        width:auto;
                                        height:100%;
                                        top:0;
                                        left:50%;
                                        transform:translate(-50%, 0);
                                    }
                                }
                            }
                            .bg {
                                background-size: cover;
                                &.pc{
                                    display:inline-block;
                                }
                                &.mobile{
                                    display:none;
                                }
                                @include mobile {
                                    height: 100%;
                                    padding-bottom: 0;
                                    &.pc{
                                        display:none;
                                    }
                                    &.mobile{
                                        display:inline-block;
                                    }
                                }
                            }
                            .slide-info-area {
                                top:100px;
                                left:0;
                                bottom:120px;
                                max-width:1614px;
                                margin:0 auto;
                                padding: 0 40px;
                                transform:translateX(0);
                                @include mobile {
                                    top:32px;
                                    right:20px;
                                    bottom:102px;
                                    left:20px;
                                    padding: 0;
                                }
                                .boxs {
                                    display:inline-block;
                                    &.fc-black {
                                        color:#000;
                                    }
                                    &.fc-white {
                                        color:#fff;
                                    }
                                    .txt {
                                        color:inherit;
                                        font-size:52px;
                                        font-weight:700;
                                        line-height:62px;
                                        @include mobile{
                                            position:relative;
                                            font-size:27px;
                                            line-height:34px;
                                            .only-desktop{
                                                display:none;
                                            }
                                        }
                                    }
                                    .small {
                                        margin-top:16px;
                                        font-size:24px;
                                        line-height:32px;
                                        @include mobile{
                                            margin-top:7px;
                                            font-size:16px;
                                            line-height:22px;
                                            .only-desktop{
                                                display:none;
                                            }
                                        }
                                    }
                                }
                            }
                            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                .slide-info-area {
                                    width:100%;
                                }
                            }
                            .link {
                                position:absolute;
                                right:0;
                                bottom:0;
                                padding-right:60px;
                                color:#fff;
                                font-size:52px;
                                font-weight:700;
                                line-height:70px;
                                text-align:right;
                                @include mobile{
                                    padding-right:22px;
                                    font-size:16px;
                                    line-height:24px;
                                }
                                &:after {
                                    content:' ';
                                    position:absolute;
                                    right:0;
                                    bottom:8px;
                                    width:56px;
                                    height:56px;
                                    background:url('/lg5-common/images/PRS/btn-arrow-right-56.svg') no-repeat 0 0;
                                    @include mobile{
                                        bottom:2px;
                                        width:20px;
                                        height:20px;
                                        background-size:20px;
                                    }
                                }
                                &.fc-black {
                                    color:#000;
                                    &:after {
                                        background-image:url('/lg5-common/images/PRS/btn-arrow-right-56-black.svg');
                                    }
                                }
                                &.fc-white {
                                    color:#fff;
                                }
                            }
                        }
                    }
                }
                .custom-indi-wrap {
                    display:none;
                    bottom:24px;
                    left:0;
                    right:0;
                    max-width:1380px;
                    margin:0 auto;
                    @include mobile{
                        bottom: 20px;
                        right: 16px;
                        left: 16px;
                        max-width: none;
                    }
                    .custom-indis {
                        max-width: (1380px + 30px);
                        @include mobile{
                            margin: 0 -5px;
                        }
                        .indis {
                            width: 100%;
                            table-layout: fixed;
                            li {
                                width: auto;
                                padding: 0 15px;
                                @include mobile{
                                    padding: 0 5px;
                                }
                                &.on {
                                    width: auto;
                                }
                            }
                        }
                    }
                    .slide-controls {
                        position:relative;
                        top:auto;
                        left:auto;
                        right:54px;
                        transform:translateX(0);
                        max-width:1380px;
                        margin:0 auto;
                        @media screen and (max-width: (1460px + 30px)) {
                            right:69px;
                        }
                        @include mobile{
                            right:34px;
                        }
                        .controls{
                            position:absolute;
                            bottom:0;
                            right:0;
                            width:150px;
                            height:46px;
                            border-radius:26px;
                            background:rgba(0, 0, 0, 0.35);
                            @include mobile{
                                width:84px;
                                height:26px;
                                border-radius:13px;
                            }
                        }
                        .btn-arrow {
                            position:absolute;
                            top:9px;
                            padding:0;
                            width:24px;
                            height:24px;
                            @include mobile{
                                top:3px;
                                width:16px;
                                height:16px;
                            }
                            &:before{
                                content:'';
                                display:block;
                                width:24px;
                                height:24px;
                                box-shadow:none;
                                @include mobile{
                                    width:16px;
                                    height:16px;
                                }
                            }
                            &.ico-prev {
                                left:16px;
                                @include mobile{
                                    left:6px;
                                }
                                &:before{
                                    background-image: url(/lg5-common/images/icons/btn-arr-24x24-lt.svg);
                                    @include mobile{
                                        background-size:16px 16px;
                                    }
                                }
                            }
                            &.ico-next {
                                right:16px;
                                @include mobile{
                                    right:6px;
                                }
                                &:before{
                                    background-image: url(/lg5-common/images/icons/btn-arr-24x24-rt.svg);
                                    @include mobile{
                                        background-size:16px 16px;
                                    }
                                }
                            }
                        }
                        .slide-page{
                            padding:0 4px;
                            color:#fff;
                            font-size:16px;
                            line-height:42px;
                            @include mobile{
                                font-size:12px;
                                line-height:23px;
                            }
                            .count{
                                color:#d6d6d6;
                                &:before{
                                    content:'/';
                                    margin:0 8px;
                                    color:#fff;
                                }
                            }
                        }
                    }
                    .indi-control {
                        display: block !important;
                        position: absolute;
                        bottom:0;
                        right:0;
                        margin-right:0;
                        width:46px;
                        height:46px;
                        @include mobile{
                            width:26px;
                            height:26px;
                        }
                        &:before{
                            width:46px;
                            height:46px;
                            @include mobile{
                                width:26px;
                                height:26px;
                            }
                        }
                        &.play{
                            &:before{
                                background: url(/lg5-common/images/icons/btn-carousel-46-play.svg) no-repeat 0 0;
                                @include mobile{
                                    background-size:26px 26px;
                                }
                            }
                        }
                        &.stop{
                            &:before{
                                background: url(/lg5-common/images/icons/btn-carousel-46-pause.svg) no-repeat 0 0;
                                @include mobile{
                                    background-size:26px 26px;
                                }
                            }
                        }
                        @media screen and (max-width: (1460px + 30px)) {
                            right: 15px;
                            margin-right: 0;
                            @include mobile {
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

// BTOCSITE-12758 LNB, Submain UI/UX개선 및 신규페이지 생성 건
// 케어솔루션 카테고리
.care-category {
    margin-top:48px;
    padding: 0;
    //220331 추가 s
    @include pc {
        padding-left: 24px;
        padding-right: 24px;
    }
    //220331 추가 e
    .category-list {
        @include mobile{
            background-color:#f4f4f4;
            .slide-wrap.slide-solo {
                padding-bottom: 0;
            }
        }
        ul {
            margin-top: 20px;
            font-size:0;
            @include mobile{
                display: flex;
                flex-wrap: wrap;
                height:100%;
                margin-top:0;
                padding: 25px 0;
                overflow:hidden;
            }
            li {
                display:inline-block;
                width:25%;
                margin-top:28px;
                @media screen and (min-width:768px) and (max-width:1200px) {
                    width:33.33%;
                }
                @include mobile{
                    display:inline-block;
                    width:25%;
                    padding: 0 5px;
                    margin-top: 17px;
                    text-align:center;
                    vertical-align:top;
                    &:nth-child(-n + 4) {
                        margin-top: 0;
                    }
                }
                a {
                    display:block;
                    overflow:hidden;
                    font-size:0;
                    @include mobile{
                        padding: 0;
                    }
                    i {
                        display:inline-block;
                        width:64px;
                        height:64px;
                        @include mobile {
                            display: none;
                            width: 40px;
                            height: 40px;
                        }
                        &.mobile {
                            display: none;
                            @include mobile {
                                display:inline-block;
                            }
                        }
                        img {
                            width:100%;
                        }
                    }
                    .txt {
                        display:inline-block;
                        position:relative;
                        padding:0 18px 0 16px;
                        font-size:20px;
                        font-weight:500;
                        line-height:28px;
                        vertical-align:middle;
                        @media screen and (max-width:980px) {
                            font-size:18px;
                            line-height:26px;
                        }
                        @include mobile{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            width:100%;
                            height: 36px;
                            margin-top:6px;
                            padding:0 2px;
                            font-size:13px;
                            line-height:19px;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            word-break: break-all;
                        }
                    }
                }
                &:hover {
                    .txt {
                        // text-decoration:underline;
                        &:after {
                            content:' ';
                            position:absolute;
                            top:50%;
                            right:0;
                            width:16px;
                            height:16px;
                            margin-top:-8px;
                            background:url('/lg5-common/images/icons/btn_arrow_right_16.svg') no-repeat 0 0;
                            @include mobile {
                                display:none;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 케어솔루션 링크
.care-home-link {
    .tit-wrap {
        @include mobile {
            margin-bottom: 19px;
        }
    }
    .link-list{
        display:flex;
        margin-left:-24px;
        @include mobile{
            margin-left:-8px;
        }
        >li{
            margin-left:24px;
            width:calc(33.33% - 24px);
            @include mobile{
                margin-left:8px;
                width:calc(33.33% - 8px);
            }
            >a{
                position:relative;
                display:table;
                padding:113px 0 30px;
                width:100%;
                height:100%;
                border-radius:8px;
                border:2px solid transparent;
                box-shadow:1px 4px 12px 0 rgba(0, 0, 0, 0.14);
                text-align:center;
                @include mobile{
                    padding:80px 5px 15px;
                    box-shadow:1px 2px 6px 0 rgba(0, 0, 0, 0.14);
                }
                .thumb{
                    position:absolute;
                    top:30px;
                    left:50%;
                    transform:translateX(-50%);
                    width:64px;
                    height:64px;
                    @include mobile{
                        top:20px;
                        width:48px;
                        height:48px;
                    }
                    img{
                        width:100%;
                    }
                }
                .tit{
                    display:table-cell;
                    position:relative;
                    vertical-align:middle;
                    font-size:24px;
                    font-weight:700;
                    line-height:34px;
                    @include mobile{
                        font-size:13px;
                        line-height:19px;
                        .pc-only{
                            display:none;
                        }
                    }
                    @include pc{
                        .mo-only{
                            display:none;
                        }
                    }
                    @media screen and (max-width: 980px){
                        .mo-only{
                            display:block;
                        }
                    }
                    .day{
                        display:inline-block;
                        @include mobile{
                            display:block;
                        }
                    }
                    .ico-arr{
                        display:inline-block;
                        position:relative;
                        top:4px;
                        width:24px;
                        height:24px;
                        background:url('/lg5-common/images/icons/btn-arr-24.svg') no-repeat 0 0;
                        @include mobile{
                            display:none;
                        }
                    }
                }
                &:hover, &:focus{
                    border:2px solid #da0f47;
                    outline:none;
                }
            }
        }
    }
}

// 케어솔루션 제품
.care-flowbanner {
    margin: 48px auto 0;
    overflow: hidden;
    @include mobile{
        margin-top:36px;
    }
    .care-home-list{
        display:flex;
        flex-wrap:wrap;
        margin:-32px 0 0 -32px;
        @include mobile{
            margin:-23px 0 0 -8px;
        }
        >li{
            margin:32px 0 0 32px;
            width:calc(33.33% - 32px);
            @media screen and (max-width: 1024px){
                text-align:center;
            }
            @include mobile{
                margin:23px 0 0 8px;
                width:calc(33.33% - 8px);
            }
            >a{
                display:inline-block;
                width:100%;
                .thumb{
                    display:inline-block;
                    width:128px;
                    max-width:calc(100% - 8px);
                    height:128px;
                    @include mobile{
                        width:auto;
                        height:auto;
                    }
                    img{
                        width:100%;
                    }
                }
                .tit{
                    display:inline-block;
                    margin-left:16px;
                    font-size:20px;
                    @media screen and (max-width: 1024px){
                        display:block;
                        margin:12px 0 0;
                        width:100%;
                        text-align:center;
                    }
                    @include mobile{
                        margin:6px 0 0;
                        font-size:13px;
                        line-height:19px;
                    }
                }
            }
        }
    }
}

// 케어솔루션 추천 제품
.care-recommended {
    max-width:1444px;
    .prd-list-wrap {
        margin-top:-8px;
        @include mobile {
            margin-top:8px;
            padding:0;
        }
        .inner {
            display:flex;
            justify-content:center;
            margin:0 auto;
            @media screen and (max-width: 1200px){
                width: 100% !important;
                transform: translate3d(0px, 0px, 0px) !important;
            }
            .item {
                width:calc(25% - 24px);
                max-width:351px;
                padding:8px 12px;
                @include mobile {
                    width:calc(50% - 4px);
                    margin:0;
                    padding:6px 4px;
                }
                @media screen and (max-width: 1200px){
                    &:nth-child(3), &:nth-child(4){
                        display:none !important;
                    }
                }
            }
            .prd-care-vertical {
                display:block;
                position:relative;
                height:100%;
                padding:40px;
                background:#fff;
                border-radius:8px;
                box-shadow:1px 4px 12px 0 rgba(0, 0, 0, 0.14);
                @media screen and (max-width: 1024px){
                    padding:20px 16px;
                }
                @include mobile {
                    // height:auto;
                    min-height:auto;
                    max-height:inherit;
                    box-shadow:1px 2px 6px 0 rgba(0, 0, 0, 0.14);
                }
                .img-wrap {
                    text-align:center;
                    img {
                        display:inline-block;
                        width:100%;
                        max-width:200px;
                        overflow:hidden;
                        @include mobile {
                            max-width:120px;
                            max-height:120px;
                        }
                    }
                }
                .txt-wrap {
                    margin-top:40px;
                    height:82px;
                    @include mobile{
                        margin-top:20px;
                        height:60px;
                    }
                    .tit {
                        max-height:56px;
                        font-size:18px;
                        line-height:28px;
                        font-weight:700;
                        overflow:hidden;
                        @include textEllipsisClamp(2);
                        @include mobile {
                            font-size: 14px;
                            line-height: 20px;
                            max-height:52px;
                        }
                    }
                    .code {
                        margin-top:2px;
                        color:#666;
                        font-size:16px;
                        line-height:24px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        white-space:nowrap;
                        @include mobile {
                            font-size:13px;
                            line-height:19px;
                        }
                    }
                }
                .info-wrap {
                    margin-top:20px;
                    .price-wrap {
                        @include mobile {
                            position:static;
                        }
                        .total-price {
                            @include clearfix;
                            .price {
                                display:inline-block;
                                @include font-large-1;
                                font-weight:700;
                                vertical-align:middle;
                                @media screen and (max-width:1235px) {
                                    @include font-large;
                                }
                                @include mobile {
                                    padding:0;
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                            .btn {
                                float:right;
                                @include mobile{
                                    float: none;
                                    display: block;
                                    margin: 0 auto;
                                    width: 100%;
                                    min-width: 130px;
                                    height: 34px;
                                    font-size: 13px;
                                    line-height: 21px;
                                    padding: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// 케어솔루션 혜택
.care-benefit {
    .benefit-cont {
        .benefit-list {
            >ul {
                display:flex;
                flex-wrap:wrap;
                >li {
                    display: flex;
                    position:relative;
                    padding:24px 40px 24px 0px; 
                    width:calc(50% - 12px);
                    border-bottom:1px solid #ddd;
                    @media screen and (max-width: 1024px){
                        margin:0;
                        width:100%;
                    }
                    @include mobile{
                        padding:18px 0;
                    }
                    &:first-child{
                        padding-top:14px;
                    }
                    &:nth-child(odd){
                        margin-right:12px;
                        @include mobile{
                            margin:0;
                            width:100%;
                        }
                        &:nth-last-child(2){
                            border-bottom:0;
                        }
                    }
                    &:nth-child(even){
                        margin-left:12px;
                        @media screen and (max-width: 1024px){
                            margin:0;
                            width:100%;
                        }
                    }
                    &:last-of-type{
                        border-bottom:0;
                    }
                    .thumb {
                        flex:none;
                        position:relative;
                        overflow:hidden;
                        width:120px;
                        height:88px;
                        margin-right:32px;
                        border-radius:8px;
                        @include mobile{
                            width:90px;
                            height:66px;
                            margin-right:20px;
                        }
                        img {
                            width:100%;
                        }
                        //BTOCSITE-7335 ui_bg_switch 추가
                        .ui_bg_switch {
                            display: block;
                            position:absolute;
                            top:0;
                            left:0;
                            width:100%;
                            height:100%;
                            background-position: 0 0;
                            background-repeat: no-repeat;
                            background-size: cover;
                            &.pc {
                                display: inline-block !important;
                                @include mobile {
                                    display: none !important;
                                }
                            }
                            &.mo {
                                display: none !important;
                                @include mobile {
                                    display: inline-block !important;
                                }
                            }
                        }
                    }
                    .cont {
                        dl {
                            dt {
                                font-size:20px;
                                font-weight:700;
                                line-height:30px;
                                @include mobile{
                                    font-size:14px;
                                    line-height:24px;
                                }
                            }
                            dd {
                                margin-top:8px;
                                font-size:18px;
                                line-height:28px;
                                word-break:keep-all;
                                @include mobile{
                                    margin-top:4px;
                                    font-size:14px;
                                    line-height:20px;
                                }
                            }
                        }
                        ul {
                            margin-top: 2px;
                            @include mobile{
                                margin-top: 12px;
                            }
                            li {
                                position: relative;
                                padding: 0;
                                border-bottom: 0;
                                padding-left: 12px;
                                font-size: 14px;
                                line-height:24px;
                                @include mobile{
                                    font-size:13px;
                                    line-height:20px;
                                }
                                &:before {
                                    content: '';
                                    width: 3px;
                                    height: 3px;
                                    border-radius: 50%;
                                    display: block;
                                    background-color: #000;
                                    position: absolute;
                                    top:12px;
                                    left: 0;
                                    @include mobile{
                                        top:8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// 다른 케어솔루션 
.care-other {
    .care-other-list{
        display:flex;
        margin-left:-24px;
        justify-content:center;
        @media screen and (max-width: 1024px){
            flex-wrap:wrap;
            margin-left:0;
        }
        li {
            position:relative;
            display: inline-block;
            margin-left:24px;
            width:calc(50% - 24px);
            @media screen and (max-width: 1024px){
                display:block;
                margin-left:0;
                max-width: 100%;
                width: 100%;
                padding: 12px 0;
            }
            @include mobile {
                padding:0;
            }
            +li {
                @include mobile{
                    margin-top:8px;
                }
            }
            > a {
                display: inline-block;
                width: 100%;
                height:100%;
                padding:48px 48px 48px 154px;
                border-radius: 8px;
                box-shadow:1px 4px 12px 0 rgba(0, 0, 0, 0.14);
                @include mobile {
                    padding:25px 8px;
                    box-shadow:1px 2px 6px 0 rgba(0, 0, 0, 0.14);
                    text-align:center;
                }
                .thumb{
                    position: absolute;
                    top: 54px;
                    left: 50px;
                    width: 64px;
                    height: 64px;
                    @include mobile{
                        top:25px;
                        left:50%;
                        transform:translateX(-50%);
                    }
                }
                .tit {
                    position: relative;
                    margin-bottom: 6px;
                    font-size: 28px;
                    line-height: 38px;
                    font-weight: 700;
                    word-break: keep-all;
                    @include mobile {
                        margin-bottom: 8px;
                        padding-top: 79px;
                        font-size:16px;
                        line-height:22px;
                        &:before {
                            width: 56px;
                            height: 56px;
                            background-size: 100%;
                        }
                    }    
                }
                .desc {
                    font-size: 18px;
                    line-height: 28px;
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                .link-text {
                    font-size: 16px;
                    line-height: 26px;
                    text-decoration: underline;
                    @include mobile {
                        font-size: 14px;
                        line-height: 22px;
                    }
                    &:after {
                        display: inline-block;
                        content: '';
                        width: 16px;
                        height: 16px;
                        background:url('/lg5-common/images/icons/btn-arr-16-black-sm.svg') center no-repeat;
                        background-size: 16px 16px;
                        vertical-align: -1px;
                    }
                }
            }
        }
    }
    .br {
        @include mobile {
            display:block;
        }
    }
}
// 내삶을바꾸는케솔
.care-banner {
    @include mobile {
        padding-left: 16px;
        padding-right: 16px;
    }
    .care-banner-list{
        display:flex;
        @media screen and (max-width: 1024px){
            flex-wrap:wrap;
        }
        li {
            flex:1;
            padding: 0 20px;
            vertical-align: top;
            @media screen and (max-width: 1024px){
                flex:none;
                width: 100%;
                padding: 0;
                margin-top: 40px;
                &:first-child {
                    margin-top: 0;
                }
            }
            .inner {
                position: relative;
                padding-left: 88px;
                min-height:64px;
                @include mobile {
                    padding-left: 80px;
                }
                .thumb{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 64px;
                    height: 64px;
                }
                .tit {
                    margin-bottom: 8px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    @include mobile {
                        margin-bottom: 4px;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                .desc {
                    @include font-medium;
                    word-break:keep-all;
                }
            }
        }
    }
    .mob-only {
        display:inline-block;
        @include mobile {
            display:block;
        }
    }
}

// BTOCSITE-20626 OBS 렌탈케어 홈 CSS 수정의 건
/* 실사 아이콘 교체 시 css 삭제 */
.care-flowbanner { 
    .care-home-list {
        margin: -28px 0 0 0px; // BTOCSITE-25132 [QA요청] 렌탈케어 홈 그리드 정렬 오류
        @include mobile {
            margin: -17px 0 0 0px;
        }
        > li {
            /* BTOCSITE-25132 [QA요청] 렌탈케어 홈 그리드 정렬 오류 s */
            //margin: 28px 0 0 32px !important;
            //width: 22.5% !important;            
            //@include mobile {
                padding: 0 5px ;
                margin: 28px 0 0 0 ;
                width: 25% ;
            //}
            @include mobile {
                margin-top: 17px;
            }
            /* BTOCSITE-25132 [QA요청] 렌탈케어 홈 그리드 정렬 오류 e */
            > a {
                .thumb {
                    width: 64px !important;
                    height: 64px !important;
                }
                .tit {
                    @include mobile {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-all;
                        height: 36px;
                    }
                }
            }            
        }
    }
}

// [정기구독3차] 렌탈/구독 메인페이지 관련 scss
@import "MKTF2001_subs";
// BTOCSITE-57339 [구독 Task] 구독 홈 개편 관련 scss
@import "MKTF2024";