.caresolution.subscribe {
    overflow: hidden;
    .care-home-section {
        margin: 150px auto 0;
        @include mobile {
            margin: 58px auto 0;
        }
        &.first {
            margin: 100px auto 0;
            @include mobile {
                margin: 36px auto 0;
            }
            /* BTOCSITE-42286 UP가전 2.0 구독관 테이블 구성,마스터, 신청 페이지 개발 s */
            ~ .first {
                margin: 150px auto 0;
                @include mobile {
                    margin: 58px auto 0;
                }
            }
            /* BTOCSITE-42286 UP가전 2.0 구독관 테이블 구성,마스터, 신청 페이지 개발 e */
        }
    }
    .care-recommended {
        .conts-recommend-wrap{
            margin-top: 32px;
            @include mobile {
                margin-top: 19px;
            }
            .tab-box-wrap {
                margin-bottom: 50px;
                @include mobile {
                    margin-bottom: 24px;
                    padding: 0 4px;
                }
                .tabs {
                    display: flex;
                    li {
                        width: 50%;
                        padding: 17px 0;
                        border:1px solid #ddd;
                        border-bottom: 1px solid #000;
                        text-align: center;
                        @include mobile {
                            padding: 10px 0;
                            border-bottom: 1px solid #ddd;
                        }
                        a {
                            color: #666666;
                            font-size: 18px;
                            line-height: 28px;
                            font-weight: bold;
                            display: block;
                            @include mobile {
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                        &.on {
                            border:1px solid #000;
                            border-bottom: 0;
                            @include mobile {
                                border-bottom: 1px solid #000;
                            }
                            a {
                                color: #000;
                            }
                        }
                    }
                }
            }
            .conts-wrap {
                .tab-content {
                    @include mobile {
                        //display: block;
                    }
                    .prd-list-wrap {
                        .item {
                            .txt-wrap {
                                @include mobile {
                                    margin-top: 13px;
                                }
                                .tit {
                                    line-height: 26px;
                                    @include mobile {
                                        line-height: 20px;
                                    }
                                }
                            }
                            .info-wrap {
                                @include mobile{
                                    margin-top: 10px;
                                    padding-top: 13px;
                                }
                                .price-wrap {
                                    .total-price {
                                        .default {
                                            color: #aaaaaa;
                                            text-decoration: line-through;
                                            font-size: 16px;
                                            line-height: 24px;
                                            @include mobile {
                                                font-size: 13px;
                                                line-height: 14px;
                                                margin-bottom: 3px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .btn-wrap {
                    margin-top: 52px;
                    display: flex;
                    justify-content: center;
                    @include mobile {
                        margin-top: 14px;
                        display: block;
                    }
                    a.btn {
                        background: #222;
                        color:#fff;
                        border: 0;
                        @include mobile {
                            width: 100%;                            
                            padding: 11px 0;
                            border-radius: 8px;
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }
                }
            }
        }
    }
    .care-benefit {
        .scroll-wrap {
            .tabs {
                > li {
                    margin-left: 24px;
                    &:first-child {
                        margin-left: 0;
                    }
                    @include mobile {
                        margin-left : 20px;   
                    }
                    > a {
                        border-width: 2px;
                    }
                }
            }
        }
        .benefit-list {
            ul {
                li {
                    .cont {
                        dl {
                            dd {
                                .btn-area {
                                    margin-top: 8px;
                                    @include mobile {
                                        margin-top: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .care-home-link {
        .link-list {
            justify-content: center;
        }
    }
    .care-other {
        .care-other-list {
            .thumb {
                img {
                    width: 64px;
                    height: 64px;
                }
            }
            .tit {
                line-height: 36px;
                margin-bottom: 8px;
                @include mobile {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }
    //230627 BTOCSTIE-22701 추가 
    .care-banner {
        .care-banner-list {
            .thumb {
                img {
                    width: 64px;
                    height: 64px;
                }
            }
        }
    }

    /* BTOCSITE-42286 UP가전 2.0 구독관 테이블 구성,마스터, 신청 페이지 개발 s */
    $pcWidth: 1380px;
    $pcPadding: 48px;
    $pcMaxWidth: $pcWidth + ($pcPadding*2);
    .care-main {
        &-banner {
            max-width: $pcMaxWidth;
            margin: 0 auto;
            padding: 0 $pcPadding;
            height: 180px;
            & + .main-banner {
                margin-top:40px;
            }
            &:last-child {
                margin-bottom:56px;
            }
            @include mobile {
                margin:32px auto 0;
                padding: 0 16px;
                height: auto;
                & + .main-banner {
                    margin-top:12px;
                }
                &:last-child {
                    margin-bottom:0;
                }
                &:only-child {
                    margin-bottom:32px;
                }
            }
            &__link {
                display: block;
                position: relative;
                height: 100%;
                border-radius: 16px;
                overflow: hidden;
                margin: 48px 0;
                @include mobile {
                    padding-top: ((110 / 328) * 100%);
                    border-radius: 8px;
                    margin: 24px 0;
                }
            }
            &__image {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: auto;
                height: 100%;
                @include mobile {
                    width: 100%;
                    height: auto;
                }
                img {
                    height: 100%;
                    @include mobile {
                        width: 100%;
                        height: auto;
                    }
                    &.pc{
                        display:inline-block !important;
                    }
                    &.mobile{
                        display:none !important;
                    }
                    @include mobile {
                        &.pc{
                            display:none !important;
                        }
                        &.mobile{
                            display:block !important;
                        }
                    }
                }
            }
            &__text-wrap {
                position: relative;
                &.text-white {
                    color: #fff;
                    .main-banner__text {
                        &:after {
                            background-image: url('/lg5-common/images/MA/mainRenewal/icon-arrow-right-white-16.svg');
                        }
                    }
                }
            }
            &__title {
                font-size: 24px;
                line-height: 32px;
                font-weight: 700;
                @include mobile {
                    font-size: 15px;
                    line-height: 21px;
                }
            }
            &__text {
                display: inline-flex;
                align-items: center;
                margin-top: 8px;
                @include mobile {
                    margin-top: 4px;
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                    opacity: 0.7;
                    @include mobile {
                        font-size: 13px;
                        line-height: 19px;
                    }
                }
                &:after {
                    content: "";
                    display: inline-block;
                    margin-left: 4px;
                    width: 16px;
                    height: 16px;
                    background: url('/lg5-common/images/MA/mainRenewal/icon-arrow-right-16.svg') center no-repeat;
                    background-size: 16px 16px;
                    opacity: 0.7;
                    @include mobile {
                        margin-left: 2px;
                        width: 12px;
                        height: 12px;
                        background-size: 12px 12px;
                    }
                }
                &-wrap {
                    position: absolute;
                    top: 50%;
                    left: 96px;
                    transform: translateY(-50%);
                    @include tablet-main {
                        left: 48px;
                    }
                    @include mobile {
                        left: 20px;
                    }
                }
            }
        }
    }
    /* BTOCSITE-42286 UP가전 2.0 구독관 테이블 구성,마스터, 신청 페이지 개발 e */

    /* BTOCSITE-30302 렌탈 서브메인 페이지 개선건 s */
    .sub-main{
        .slide-wrap {
            &.hero-banner {
                .slide-content {
                    @include mobile{
                        .slide-track {
                            height:102.73vw;
                            .slide-conts {
                                height:100%;
                                .slide-img {
                                    img {
                                        top: auto;
                                        bottom: 0;
                                        left: 0;
                                        transform: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /* BTOCSITE-30302 렌탈 서브메인 페이지 개선건 e */

}