// S : BTOCSITE-77997 [전시] 가전 구독 메인 히어로배너 
// 히어로배너 비율계산
@mixin pc-hero {
    @media screen and (min-width:#{$tablet-s-width + 1px}) and (max-width:#{$desktop-full-width}) {
        @content;
    }
}

$size--desktop: 1920px;
$size--mobile: 360px;

// 단위 제거 함수
@function deUnit($value) {
    @return ($value / ($value * 0 + 1));
}

// 1920px 이하에서 사용될 vw-hero-pc(속성, 값)
@mixin vw-hero-pc($property, $pixel){
    @if length($pixel) == 1 {
        #{$property}: deUnit($pixel) * 100 / deUnit($size--desktop) * 1vw
    } @else if length($pixel) == 2 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
    } @else if length($pixel) == 3 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,3)) * 100 / deUnit($size--desktop) * 1vw
    } @else if length($pixel) == 4 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,3))* 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,4)) * 100 / deUnit($size--desktop) * 1vw
    }
}

// 360px 이상에서 사용될 vw-hero-mo(속성, 값)
@mixin vw-hero-mo($property, $pixel){
    @if length($pixel) == 1 {
        #{$property}: deUnit($pixel) * 100 / deUnit($size--mobile) * 1vw;
    } @else if length($pixel) == 2 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw;
    } @else if length($pixel) == 3 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,3)) * 100 / deUnit($size--mobile) * 1vw;
    } @else if length($pixel) == 4 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,3)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,4)) * 100 / deUnit($size--mobile) * 1vw;
    }
}
// E : BTOCSITE-77997 [전시] 가전 구독 메인 히어로배너 


// BTOCSITE-84773 구독홈 플로팅버튼 위치 수정 (퀵 메뉴와 겹침)
.app {
    .caresolution-renewal {
        .floating-message {
            &.on {
                bottom: 5px;
            }
        }
    }
}

// BTOCSITE-57339 [구독 Task] 구독 홈 개편 관련 scss
.caresolution-renewal {
    .caresolution.subscribe {
        .caresolution-main{
            padding-bottom: 0;
            @include mobile {
                padding-bottom: 50px;
            }
            .inner {
                max-width: 1476px;
                margin: 50px auto 80px;
                padding: 0 48px;
                @include mobile {
                    margin: 24px 0 0;
                    padding: 0 16px;
                }
                &.caresolution-link-wrap {
                    margin-top: 40px;
                }
            }
            .section-title {
                margin-bottom: 16px;
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: -2%;
            }

            .section{
                &.wide-section {
                    position: relative;
                    width: 100% !important;
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 0;
                    padding-right: 0;
                }
                &__inner {
                    padding: 0px 48px 30px;
                    max-width: 1476px;
                    margin: 0 auto;
                    position: relative;
                }
                &__header {
                    margin-bottom: 28px;
                }
                &__title {
                    font-size: 28px;
                    line-height: 36px;
                    color: #000;
                    font-weight: 700;
                }
                @include mobile {
                    &__header {
                        margin-bottom: 16px;
                    }
                    &__title {
                        font-size: 16px;
                        line-height: 1.448125;
                    }
                }
            }

            // 히어로 배너
            .caresolution-hero-wrap {
                margin: 0 auto;
                max-width: 1920px;
                .swiper-pointer-events {
                    touch-action: pan-y;
                }
                .swiper {
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;
                    overflow: hidden;
                    list-style: none;
                    padding: 0;
                    z-index: 1;
                }
                .swiper-wrapper {
                }
                .swiper-slide {
                    position: relative;
                    width: 100%;
                    height: 560px;
                    @include pc-hero {
                        @include vw-hero-pc(height, 560px);
                    }
                    
                    .item {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                    .slide-img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        img {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 100%;
                            height: auto;
                            min-width: 100%;
                            min-height: 100%;
                            max-width: none;
                        }
                        video {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 100%;
                            height: auto;
                            min-width: 100%;
                            min-height: 100%;
                            max-width: none;
                            object-fit: cover;
                        }
                    }
                    .slide-info-area {
                        position: absolute;
                        top: 166px;
                        left: 50%;
                        max-width: 1460px;
                        width: 100%;
                        padding: 0 40px;
                        color: #fff;
                        transform: translateX(-50%);
                        @include pc-hero {
                            @include vw-hero-pc(top, 166px);
                            @include vw-hero-pc(max-width, 1460px);
                            @include vw-hero-pc(padding, 0 40px);
                        }
                        .txt {
                            width: 560px;
                            font-size: 50px;
                            line-height: 1.2;
                            font-weight: 700;
                            word-break: keep-all;
                            @include pc-hero {
                                @include vw-hero-pc(font-size, 50px);
                                @include vw-hero-pc(width, 560px);
                            }
                        }
                        .small {
                            margin-top: 24px;
                            width: 560px;
                            font-size: 24px;
                            line-height: 1.333333;                            
                            @include pc-hero {
                                @include vw-hero-pc(margin-top, 24px);
                                @include vw-hero-pc(font-size, 24px);
                                @include vw-hero-pc(width, 560px);
                            }
                        }
                    }
                    @include mobile {
                        @include vw-hero-mo(height, 340px);
                        .slide-info-area {
                            @include vw-hero-mo(top, 32px);
                            left: 0;
                            transform: none;
                            @include vw-hero-mo(padding, 0 20px);
                            .txt {
                                @include vw-hero-mo(font-size, 20px);
                                line-height: 1.3;
                            }
                            .small {
                                @include vw-hero-mo(margin-top, 12px);                                
                                @include vw-hero-mo(font-size, 14px);
                                line-height: 1.428571;
                            }
                        }
                    }
                }
                .full-link {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
                // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                .custom-indi-wrap {
                    position: absolute;
                    left:50%;
                    bottom: 32px;
                    max-width: 1380px;
                    width:100%;
                    margin:0 auto;
                    padding:0 24px;
                    transform:translateX(-50%);
                    z-index: 1;
                    @include pc-hero {
                        @include vw-hero-pc(bottom, 32px);
                        @include vw-hero-pc(max-width, 1380px);
                        @include vw-hero-pc(padding, 0 24px);
                    }
                    @include mobile {
                        left:auto;
                        transform:none;
                    }
                    .slide-controls {
                        padding-right: 0;
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                        border-radius:100px;
                        background:rgba(0,0,0,0.5);
                        position:absolute;
                        bottom:0;
                        right:24px;
                        padding-right:12px;
                        @include pc-hero {
                            @include vw-hero-pc(border-radius, 100px);
                            @include vw-hero-pc(padding-right, 12px);
                            @include vw-hero-pc(right, 24px);
                        }
                        @include mobile {
                            right:0;
                        }
                        .controls {
                            position: relative;
                            font-size: 0;
                            text-align: center;
                        }
                        .btn-arrow {
                            @include pc {
                                display:none;
                            }
                            position: absolute;
                            top: 50%;
                            padding: 0;
                            width: 24px;
                            height: 24px;
                            transform: translateY(-50%);
                            @include pc-hero {
                                @include vw-hero-pc(width, 24px);
                                @include vw-hero-pc(height, 24px);
                            }
                            &::before {
                                content: ' ';
                                display: block;
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background-repeat: no-repeat;
                                background-size: 100%;
                                box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
                                @include pc-hero {
                                    @include vw-hero-pc(width, 24px);
                                    @include vw-hero-pc(height, 24px);
                                    @include vw-hero-pc(box-shadow, 2px 4px 16px 0 rgba(0, 0, 0, 0.14));
                                }
                            }
                            &.ico-prev {
                                &::before {
                                    background-image: url(/lg5-common/images/icons/btn-arr-24x24-lt.svg);
                                }
                            }
                            &.ico-next {
                                &::before {
                                    background-image: url(/lg5-common/images/icons/btn-arr-24x24-rt.svg);
                                }
                            }
                        }
                        .slide-page {
                            padding:0 8px 0 5px;
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: -.64px;
                            color: #fff;
                            font-weight: 700;
                            @include pc-hero {
                                @include vw-hero-pc(padding, 0 8px 0 5px);
                                @include vw-hero-pc(font-size, 14px);
                                @include vw-hero-pc(line-height, 20px);
                            }
                            .current {
                                font-weight: 700;
                            }
                            .count {
                                &::before {
                                    content: '/';
                                    color: #fff;
                                    font-size:13px;
                                    margin: 0 3px;
                                    font-weight:400;
                                    @include pc-hero {
                                        @include vw-hero-pc(font-size, 13px);
                                        @include vw-hero-pc(margin, 0 3px);
                                    }
                                }
                            }
                        }
                    }
                    .indi-control {
                        position: relative;
                        font-size: 0;
                        text-align: center;
                        width: 32px;
                        height: 32px;
                        @include pc-hero {
                            @include vw-hero-pc(width, 32px);
                            @include vw-hero-pc(height, 32px);
                        }
                        &::before {
                            content: '';
                            display: inline-block;
                            background-size: 100% 100%;
                            background-image: url(/lg5-common/images/icons/btn-carousel-32-play.svg);
                            width: 32px;
                            height: 32px;
                            @include pc-hero {
                                @include vw-hero-pc(width, 32px);
                                @include vw-hero-pc(height, 32px);
                            }
                        }
                        &.play::before {
                            background-image: url(/lg5-common/images/icons/btn-carousel-32-pause.svg);
                        }
                    }
                    @include mobile {
                        @include vw-hero-mo(bottom, 16px);
                        @include vw-hero-mo(right, 16px);
                        @include vw-hero-mo(padding, 0);                        
                        @include vw-hero-mo(width, 96px); 
                        .slide-controls {
                            @include vw-hero-mo(height, 24px);
                            @include vw-hero-mo(padding-right, 6px);
                            .controls {
                                @include vw-hero-mo(width, 62px);
                                @include vw-hero-mo(height, 16px);
                                @include vw-hero-mo(padding, 0 6px 0 4px);
                            }
                            .btn-arrow {
                                @include vw-hero-mo(width, 16px);
                                @include vw-hero-mo(height, 16px);
                                &.ico-prev {
                                    left:0;
                                }
                                &.ico-next {
                                    right:0;
                                }
                                &::before {
                                    @include vw-hero-mo(width, 16px);
                                    @include vw-hero-mo(height, 16px);
                                }
                            }
                            .slide-page {
                                padding: 0;                                
                                @include vw-hero-mo(line-height, 15px);
                                @include vw-hero-mo(font-size, 11px);
                                .count {
                                    &:before {
                                        @include vw-hero-mo(margin, 0 2px);
                                        @include vw-hero-mo(font-size, 10px);
                                    }
                                }
                            }
                        }
                        .indi-control {
                            @include vw-hero-mo(width, 24px);
                            @include vw-hero-mo(height, 24px);
                            &:before {
                                @include vw-hero-mo(width, 24px);
                            @include vw-hero-mo(height, 24px);
                            }
                            &.play {
                                &:before {
                                    @include vw-hero-mo(background-size, 24px 24px);
                                }
                            }
                            &.stop {
                                &:before {
                                    @include vw-hero-mo(background-size, 24px 24px);
                                } 
                            }
                        }
                    }
                }
                .video-button-wrap {
                    position: absolute;
                    bottom: 32px;
                    left: 50%;
                    margin: 0 auto;
                    padding: 0 24px;
                    max-width: 1380px;
                    width: 100%;
                    transform: translateX(-50%) translateZ(1px);
                    z-index: 1;
                    @include pc-hero {
                        @include vw-hero-pc(bottom, 32px);
                        @include vw-hero-pc(padding, 0 24px);
                        @include vw-hero-pc(max-width, 1380px);
                    }
                    .video-button {
                        left:24px;
                        width:32px;
                        height: 32px;
                        background: url(/lg5-common/images/icons/btn-carousel-32-play-round.svg) center no-repeat;
                        background-size: 100% auto;
                        pointer-events: all;
                        
                    @include pc-hero {
                        @include vw-hero-pc(width, 32px);
                        @include vw-hero-pc(height, 32px);
                        @include vw-hero-pc(left, 24px);
                    }
                        &.pause {
                            background-image: url(/lg5-common/images/icons/btn-carousel-32-pause-round.svg);
                        }
                    }
                    @include mobile {
                        position: absolute;
                        @include vw-hero-mo(bottom, 16px);
                        left: 50%;
                        margin: 0 auto;
                        @include vw-hero-mo(padding, 0 16px);
                        // max-width: 1460px;
                        width: 100%;
                        transform: translateX(-50%) translateZ(1px);
                        z-index: 1;
                        .video-button {
                            @include vw-hero-mo(width, 25px);
                            @include vw-hero-mo(height, 25px);

                            background: url(/lg5-common/images/icons/btn-carousel-32-pause-round.svg) center no-repeat;
                            background-size: 100% auto;
                            pointer-events: all;
                        }
                    }
                }
                .slide-arrow {
                    button {
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        z-index:1;   
                        width:48px;
                        height:48px;
                        padding:0;
                        border-radius:100%;
                        box-shadow:1px 3px 16px 0px rgba(0, 0, 0, 0.14);
                        font-size:initial;
                        @include pc-hero {
                            @include vw-hero-pc(width, 48px);
                            @include vw-hero-pc(height, 48px);
                            @include vw-hero-pc(box-shadow, 1px 3px 16px 0px rgba(0, 0, 0, 0.14));
                        }
                        &:before {
                            display:none;
                        } 
                        &.arrow-prev {
                            left:100px;
                            background:url(/lg5-common/images/icons/arrow_left_32.svg) no-repeat center center / 32px 32px;
                            background-color:rgba(255, 255, 255, 0.85);
                            @include pc-hero {
                                @include vw-hero-pc(left, 100px);
                                @include vw-hero-pc(background-size, 32px 32px);
                            }
                        }
                        &.arrow-next {
                            right:100px;
                            background:url(/lg5-common/images/icons/arrow_right_32.svg) no-repeat center center / 32px 32px;
                            background-color:rgba(255, 255, 255, 0.85);
                            @include pc-hero {
                                @include vw-hero-pc(right, 100px);
                                @include vw-hero-pc(background-size, 32px 32px);
                            }
                        }
                    } 
                    @include mobile {
                        display:none;
                    }
                }
                // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
            }  
            
            // 구독 이용 가이드 혜택 바로가기
            .caresolution-link-wrap {
                display: flex;
                &.inner {
                    margin-bottom: 0;
                }
                .link-box-wrap {
                    display: flex;
                    width: calc((100% - 24px) / 2);
                    gap: 24px;
                }
                .link-box {
                    position: relative;
                    width: calc((100% - 24px) / 2);
                    border-radius: 16px;
                    overflow: hidden;
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                    }
                    & > a {
                        padding: 50px 0 0 32px;
                        display: block;
                        width: 100%;
                        height: 100%;
                        letter-spacing: -2%;
                        .tit {
                            display: block;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 1.448125;
                            color: #777;
                        }
                        .context {
                            position: relative;
                            display: block;
                            margin-top: 2px;
                            width: 51.5%;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 1.447916;
                            letter-spacing: -0.7px;
                            z-index: 2;
                            &::after {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                bottom: -49px;
                                left: 0;
                                width: 40px;
                                height: 40px;
                                background: url(/lg5-common/images/SLS/2024/icon_arrow_purple.png)no-repeat center / cover;
                            }
                            & > br {
                                display: block;
                            }
                        }
                    }
                    &.guide {
                        margin-right: 24px;
                        width: calc((100% - 24px) / 2);
                        aspect-ratio: 1 / 0.41298;
                        background: #f2f2f5;
                        .context {
                            width: 38.053097%;
                            letter-spacing: -0.7px;
                            br {
                                &:nth-child(2){
                                    display: block;
                                }
                            }
                        }
                        &::after {
                            bottom: 0;
                            right: 59px;
                            width: 241.26px;
                            height: 182px;
                            background: url(/lg5-common/images/SLS/2024/img_rental.png)no-repeat 0 0 / 100% auto;
                        }
                    }
                    &.card {
                        background: #eaeef3;
                        &::after {
                            bottom: 20px;
                            right: 24px;
                            width: 100px;
                            height: 100px;
                            background: url(/lg5-common/images/SLS/2024/img_card.png)no-repeat 0 0 / cover;
                        }
                        & > a {
                            .context {
                                &::after {
                                    background: url(/lg5-common/images/SLS/2024/icon_arrow_blue.png)no-repeat center / cover;
                                }
                            }
                        }
                    }
                    &.etimate {
                        background: #efefed;
                        &::after {
                            bottom: 20px;
                            right: 24px;
                            width: 100px;
                            height: 100px;
                            background: url(/lg5-common/images/SLS/2024/img_estimate.png)no-repeat 0 0 / cover;
                        }
                        & > a {
                            .context {
                                &::after {
                                    background: url(/lg5-common/images/SLS/2024/icon_arrow_green.png)no-repeat center / cover;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1476px) {
                    .link-box {
                        border-radius: 1.073826vw;
                        & > a {
                            padding: 3.154362vw 0 0 2.147651vw;
                            .context {
                                width: 57%;
                                letter-spacing: -0.046980vw;
                                &::after {
                                    bottom: -3.020134vw;
                                    width: 2.684564vw;
                                    height: 2.684564vw;
                                }
                            }
                        }
                        &.guide {
                            .context {
                                width: 43%;
                            }
                            &::after {
                                right: 5vw;
                                width: 15.906040vw;
                                height: 15.906040vw;
                            }
                        }
                        &.card {
                            &::after {
                                bottom: 1.610738vw;
                                right: 1.610738vw;
                                width: 6.711409vw;
                                height: 6.711409vw;
                            }
                        }
                        &.etimate {
                            &::after {
                                bottom: 1.610738vw;
                                right: 1.610738vw;
                                width: 6.711409vw;
                                height: 6.711409vw;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1280px) {
                    flex-wrap: wrap;
                    .link-box-wrap {
                        width: 100%;
                        gap: 24px;
                    }
                    .link-box {
                        width: calc((100% - 1.875vw) / 2);
                        & > a {
                            padding: 3.125vw 0 7.0615vw 2.5vw;
                            .context {
                                letter-spacing: -0.054687vw;
                                width: 53%;
                                &::after {
                                    bottom: -3.515625vw;
                                    width: 3.125vw;
                                    height: 3.125vw;
                                }
                                & > br {
                                    display: block;
                                }
                            }
                        }
                        &.guide {
                            margin-right: 0;
                            margin-bottom: 1.875vw;
                            width: 100%;
                            aspect-ratio: auto;
                            .context {
                                letter-spacing: -0.054687vw;
                                & > br {
                                    display: block;
                                    &:nth-child(2){
                                        display: block;
                                    }
                                }
                            }
                            &::after {
                                right: 4.296875vw;
                                width: 17.578125vw;
                                height: 13.21vw;
                            }
                        }
                        &.card {
                            &::after {
                                bottom: 1.5625vw;
                                right: 1.875vw;
                                width: 7.8125vw;
                                height: 7.8125vw;
                            }
                            .context {
                                & > br {
                                    display: block;
                                }
                            }
                        }
                        &.etimate {
                            &::after {
                                bottom: 1.5625vw;
                                right: 1.875vw;
                                width: 7.8125vw;
                                height: 7.8125vw;
                            }
                        }
                    }
                }
                @include mobile {
                    &.inner {
                        margin-top: 24px;
                    }
                    .link-box-wrap {
                        display: flex;
                        flex-direction: column;
                        width: calc((100% - 8px) / 2);
                        gap: 8px;
                    }
                    .link-box {
                        width: 100%;
                        border-radius: 8px;
                        background: lightpink;
                        overflow: hidden;
                        & > a {
                            padding: 14px 0 0 18px;
                            display: block;
                            width: 100%;
                            height: 100%;
                            letter-spacing: -2%;
                            .tit {
                                font-size: 12px;
                                line-height: 1.448333;
                            }
                            .context {
                                margin-top: 2px;
                                width: 51.5%;
                                min-width: 92px;
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 1.357142;
                                letter-spacing: -0.7px;
                                &::after {
                                    bottom: -28px;
                                    width: 20px;
                                    height: 20px;
                                }
                                & > br {
                                    display: block;
                                }
                            }
                        }
                        &.guide {
                            margin-right: 8px;
                            margin-bottom: 0;
                            width: calc((100% - 8px) / 2);
                            aspect-ratio: auto;
                            background: #f2f2f5;
                            .context {
                                width: 100%;
                                min-width: 106px;
                                letter-spacing: -0.7px;
                                & > br {
                                    &:nth-child(2){
                                        display: none;
                                    }
                                }
                            }
                            &::after {
                                width: 119.35px;
                                height: 90.04px;
                                right: auto;
                                left: 50%;
                                transform: translateX(-50%);
                                bottom: 0;
                            }
                        }
                        &.card {
                            height: 110px;
                            background: #eaeef3;
                            &::after {
                                width: 50px;
                                height: 50px;
                                right: 6px;
                                bottom: 2px;
                            }
                        }
                        &.etimate {
                            height: 110px;
                            background: #efefed;
                            &::after {
                                width: 50px;
                                height: 50px;
                                right: 6px;
                                bottom: 2px;
                            }
                        }
                    }
                }
            }

            // 구독 카테고리별 인기제품
            .caresolution-main-bestranking {
                .section-inner {
                    margin: 0 auto;
                    padding: 80px 40px;
                    max-width: 1460px;
                }
                .section-title {
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 36px;
                }
                .section-content {
                    margin-top: 26px;
                }
                .best-product {
                    margin-top: -6px;
                    .tabs-wrap-new {
                        position: relative;
                        display: block;
                        max-width: 1380px;
                        margin: 0 auto;
                        background-color: #fff;
                        margin-left:-4px;
                        .tabs {
                            position: relative;
                            display: inline-block;
                            font-size: 0;
                            white-space: nowrap;
                            padding: 0 10px 0 0;
                            max-width: initial;
                            overflow-x: initial;
                            padding-left:4px;
                            & > li {
                                margin: 6px 0 6px 8px;
                                display: inline-block;
                                vertical-align: top;
                                height: auto;
                                &:first-child {
                                    margin-left: 0;
                                }
                                & > a {
                                    position: relative;
                                    display: inline-block;
                                    height: 40px;
                                    padding: 0 24px;
                                    border-radius: 40px;
                                    background-color: #fff;
                                    border: 1px solid #8f8f8f; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                    color: #000;
                                    font-size: 16px;
                                    line-height: 38px;
                                    font-weight: 400;
                                    white-space: nowrap;
                                }
                                &.on {
                                    a {
                                        padding: 0 22px;
                                        font-weight: 700;
                                        border-radius: 20px;
                                        border: 1px solid #000;
                                        color: #fff;
                                        background-color: #000;
                                        letter-spacing: -0.3px;
                                        &::after {
                                            display: none;
                                        }
                                        &:focus-visible {
                                            outline:3px dotted #000;
                                        }
                                    }
                                }
                            }
                        }
                        .arrow-btn {
                            position: absolute;
                            top: 0;
                            width: 100%;
                            z-index: 1;
                            button {
                                position: absolute;
                                top: 0;
                                width: 50px;
                                height: 50px;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    width: 123px; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                    height: 100%;
                                    transform: translateY(-50%);
                                }
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    width: 40px;
                                    height: 40px;
                                    transform: translateY(-50%);
                                }
                            }
                            // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                            .prev {
                                left: 0;
                                &::before {
                                    left: 0;
                                    background-image: linear-gradient(90deg, #FFF 56.38%, rgba(255, 255, 255, 0.84) 78.98%, rgba(255, 255, 255, 0.00) 100%);
                                }
                                &::after {
                                    top: 5px;
                                    left: 0;
                                    transform: rotate(180deg);
                                    background: url(/lg5-common/images/SLS/2024/btn_circle_arr.png) no-repeat 50% 50%/100% auto;
                                }
                            }
                            .next {
                                right: 0;
                                &::before {
                                    right: 0;
                                    background-image: linear-gradient(270deg, #FFF 56.38%, rgba(255, 255, 255, 0.84) 78.98%, rgba(255, 255, 255, 0.00) 100%);
                                }
                                &::after {
                                    right: 0;
                                    background: url(/lg5-common/images/SLS/2024/btn_circle_arr.png) no-repeat 50% 50%/100% auto;
                                }
                            }
                            // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                        }
                        &::before {
                            display: none;
                        }
                    }
                    .best-prd-cont {
                        margin-top: 0;
                    }
                }
                .swiper-3d {
                    perspective: 1200px;
                    .swiper-wrapper {
                        transform-style: preserve-3d;
                    }
                    .swiper-slide {
                        transform-style: preserve-3d;
                    }
                }
                .swiper-pointer-events {
                    touch-action: pan-y;
                }
                .swiper {
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;
                    overflow: hidden;
                    list-style: none;
                    padding: 0;
                    z-index: 1;
                }
                .swiper-wrapper {
                    transform: translate3d(0, 0, 0);
                    position: relative;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    display: flex;
                    transition-property: transform;
                    box-sizing: content-box;
                }
                .swiper-creative {
                    .swiper-slide {
                        backface-visibility: hidden;
                        overflow: hidden;
                        transition-property: transform, opacity, height;
                    }
                }
                .swiper-slide {
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    transition-property: transform;
                }
                .best-prd-list {
                    display: flex;
                }
                .main-bestranking {
                    &__list {
                        display: flex;
                        margin-top: 28px;
                    }
                    &__item {
                        position: relative;
                        margin-left: 1.73913%;
                        width: 23.695653%;
                        border-radius: 16px;
                        overflow: hidden;
                        & > a {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                        .item {
                            &__name {
                                margin: 16px 0 2px;
                                width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                .main {
                                    font-size: 20px;
                                    line-height: 1.4;
                                    font-weight: 700;
                                    .sub-text-new {
                                        .sub {
                                            font-weight: 400;
                                            color: #333;
                                            &::before {
                                                content: ' ·';
                                                margin: 0 3px;
                                                line-height: 18.2px;
                                                font-weight: 400;
                                                margin-left: 0;
                                            }
                                        }
                                        
                                    }
                                }
                            }
                            &__sku {
                                font-size: 16px;
                                line-height: 1.5;
                                color: #666;
                            }
                            &__price {
                                display: block;
                                &--current {
                                    margin-top: 8px;
                                    font-size: 20px;
                                    line-height: 1.4;
                                }
                                &--benefit {
                                    font-size: 16px;
                                    line-height: 1.5;
                                    color: #EA1917;
                                }
                            }
                            &__image {
                                position: relative;
                                width: 100%;
                                aspect-ratio: 1 / 1;
                                border-radius: 16px;
                                overflow: hidden;
                                // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                .rank {
                                    position:absolute;
                                    top:16px;
                                    left:16px;
                                    display:inline-block;
                                    width:9.79%;
                                    aspect-ratio: 1 / 1;
                                    z-index:3;
                                }
                                & > img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%,-50%);
                                    display: block;
                                    width: 83%;
                                    height: 83%;
                                }
                                // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                &::after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background: #1A180E;
                                    opacity: 0.05;
                                }
                                // S : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                .badge.lge-only {
                                    top:24px;
                                    right:24px;
                                    @include tablet-main {
                                        top:12px;
                                        right:12px;
                                    }
                                }
                                // E : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                // S : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                .middle-badge-list {
                                    position:absolute;
                                    top:20px;
                                    right:20px;
                                    display:flex;
                                    flex-direction:column;
                                    flex-wrap:wrap;
                                    z-index:1;  
                                    gap:6px;
                                    > span {
                                        position:unset;
                                        display:block;
                                        width:48px;
                                        height:48px;
                                        &.badge-lge-only {
                                            background:url(/lg5-common/images/icons/badge_lge_only.svg) no-repeat 0 0 / 100%;    
                                        }
                                        &.badge-gift-card {
                                            background:url(/lg5-common/images/icons/badge_gift_card.svg) no-repeat 0 0 / 100%;
                                        }
                                        &.badge-gift {
                                            background:url(/lg5-common/images/icons/badge_gift.svg) no-repeat 0 0 / 100%;    
                                        }
                                        &.badge-discount {
                                            background:url(/lg5-common/images/icons/badge_discount.svg) no-repeat 0 0 / 100%;        
                                        }
                                        &.badge-point {
                                            background:url(/lg5-common/images/icons/badge_point.svg) no-repeat 0 0 / 100%;
                                        }
                                    }  
                                    @include mobile {
                                        // BTOCSITE-80456 메인홈, 구독홈 엠블럼(Image label) 오류 노출 건
                                        top:8px;
                                        right:8px;
                                        gap:2px;
                                        > span {
                                            width:38px;
                                            height:38px;
                                        }
                                    }
                                }
                            }
                            // E : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                        }
                        &:first-child {
                            margin-left: 0;
                            width: 49.130434%;
                            height: 486px;
                            & > a {
                                padding: 60px 20px 0 32px;
                            }
                            .item {
                                &__info {
                                    position: relative;
                                    z-index: 2;
                                    width: 254px;
                                }
                                &__title {
                                    margin-bottom: 60px;
                                    .desc {
                                        display: block;
                                        font-size: 16px;
                                        font-weight: 500;
                                        line-height: 1.25;
                                    }
                                    // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                    .tit {
                                        position:relative;
                                        display: block;
                                        font-size: 36px;
                                        font-weight: 700;
                                        line-height: 52px;
                                        letter-spacing: -1px;
                                        width: 85%;
                                        span {
                                            position:absolute;
                                            display:inline-block;
                                            bottom:0;
                                            width:48px;
                                            height:48px;
                                            margin-left:10px;
                                            background: url(/lg5-common/images/SLS/2024/icon_ranking_best_1.png) no-repeat 0 0 / cover;
                                        }
                                    }
                                    // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                }
                                &__name {
                                    width: 88%;
                                }
                                &__price {
                                    display: block;
                                    &--current {
                                        margin-top: 24px;
                                        font-size: 22px;
                                        line-height: 1.272727;
                                    }
                                    &--benefit {
                                        font-size: 20px;
                                        line-height: 1.4;
                                    }
                                }
                                &__image {
                                    width: 49.262536%;
                                    position: absolute;
                                    bottom: 20px;
                                    right: 20px;
                                    aspect-ratio: 1 / 1;
                                    & > img {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                    }
                                    &::before {
                                        display: none;
                                    }
                                    &::after {
                                        display: none;
                                    }
                                    // S : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                    .middle-badge-list {
                                        top:0;
                                        right:0;
                                    }
                                    // E : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                }
                            }
                            & > a {
                                &::after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background: #1A180E;
                                    opacity: 0.05;
                                }
                            }
                        }
                        &:nth-child(2){
                            .item {
                                &__image {
                                    // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                    .rank {
                                        background: url(/lg5-common/images/SLS/2024/icon_ranking_best_2.png) no-repeat 0 0 / cover;
                                    }
                                    // BTOCSITE-80456 메인홈, 구독홈 엠블럼(Image label) 오류 노출 건
                                    .middle-badge-list {
                                        @include mobile {
                                            > span {
                                                width: 32px;
                                                height: 32px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:nth-child(3){
                            .item {
                                &__image {
                                    // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                    .rank {
                                        background: url(/lg5-common/images/SLS/2024/icon_ranking_best_3.png) no-repeat 0 0 / cover;
                                    }
                                    // BTOCSITE-80456 메인홈, 구독홈 엠블럼(Image label) 오류 노출 건
                                    .middle-badge-list {
                                        @include mobile {
                                            > span {
                                                width: 32px;
                                                height: 32px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1476px){
                    .main-bestranking {
                        &__item {
                            &:first-child {
                                width: 49.130434%;
                                aspect-ratio: auto;
                                & > a {
                                    padding: 4.065041vw 1.355014vw 0 2.168022vw;
                                }
                                .item {
                                    &__title {
                                        margin-bottom: 4.065041vw;
                                    }
                                    &__price {
                                        display: block;
                                        &--current {
                                            margin-top: 1.626016vw;
                                        }
                                    }
                                    &__image {
                                        width: 22.628726vw;
                                        bottom: 1.355014vw;
                                        right: 1.355014vw;
                                        // S : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                        .badge.lge-only {
                                            top:0.625vw;
                                            right:0.625vw; 
                                        }
                                        // E : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                    }
                                }
                            }
                        }
                    }
                    .section-inner {
                        padding: 5.420054vw 40px;
                    }
                }
                // S : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                @include mobile {
                    .main-bestranking {
                        &__item {
                            &:first-child {
                                .item {
                                    &__image {
                                        .badge.lge-only {
                                            top:12px;
                                            right:12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // E : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                @include mobile{
                    .section-inner {
                        padding: 50px 0px;
                    }
                    .section-title {
                        padding: 0 16px;
                        font-size: 16px;
                        line-height: 1.448125;
                    }
                    .section-content {
                        margin-top: 16px;
                    }
                    .best-product {
                        margin-top: -6px;
                        .tabs-wrap-new {
                            margin:-4px 0 -4px 13px;
                            .tabs {
                                position: relative;
                                display: inline-block;
                                font-size: 0;
                                white-space: nowrap;
                                padding: 4px 10px 4px 3px;
                                max-width: initial;
                                overflow-x: initial;
                                & > li {
                                    margin: 0 6px 0px 0px;
                                    padding: 0;
                                    & > a {
                                        padding: 0 12px;
                                        height: auto;
                                        border: 1px solid #8f8f8f; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                        border-radius: 16px;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 30px;
                                        color: #000;
                                        background: #fff;
                                    }
                                    &.on {
                                        a {
                                            padding: 0 12px;
                                            font-weight: 700;
                                            border-radius: 16px;
                                            &::after {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                            .arrow-btn {
                                display: none;
                            }
                            &::before {
                                display: none;
                            }
                        }
                        .best-prd-cont {
                            margin-top: 16px;
                            padding-bottom: 32px;
                            position: relative;
                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: #1a180e; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                opacity:.05;
                            }
                        }
                    }
                    .best-prd-list {
                        display: flex;
                    }
                    .main-bestranking {
                        &__list {
                            margin-top: 0;
                            flex-wrap: wrap;
                        }
                        &__item {
                            position: relative;
                            margin-left: 16px;
                            width: 100%;
                            border-radius: 8px;
                            overflow: hidden;
                            & > a {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                            .item {
                                &__info {
                                    width: 200px;
                                }
                                &__name {
                                    margin-top: 0;
                                    .main {
                                        font-size: 13px;
                                    }
                                }
                                &__sku {
                                    display: block;
                                    margin-top: 2px;
                                    font-size: 11px;
                                }
                                &__price {
                                    display: block;
                                    &--current {
                                        margin-top: 8px;
                                        font-size: 13px;
                                        line-height: 1.4;
                                    }
                                    &--benefit {
                                        font-size: 13px;
                                        line-height: 1.5;
                                        color: #EA1917;
                                    }
                                }
                                &__link {
                                    display: flex;
                                    align-items: start;
                                }
                                &__image {
                                    margin-right: 12px;
                                    position: relative;
                                    width: 110px;
                                    aspect-ratio: 1 / 1;
                                    border-radius: 8px;
                                    overflow: hidden;
                                    background-color: #fff;
                                    // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                    .rank {
                                        top:4px;
                                        left:4px;
                                        width:20px;
                                    }
                                    // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                    &::after {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        background: #fff;
                                        opacity: 0.05;
                                    }
                                    & > img {
                                        width: 94px;
                                        height: 94px;
                                    }
                                }
                            }
                            &:first-child {
                                margin-left: 0;
                                width: 100%;
                                height: 225px;
                                aspect-ratio: auto;
                                & > a {
                                    padding: 32px 0 16px 24px;
                                }
                                .item {
                                    &__info {
                                        position: relative;
                                        z-index: 2;
                                        width: 170px;
                                    }
                                    &__title {
                                        margin-bottom: 10px;
                                        .desc {
                                            font-size: 12px;
                                        }
                                        .tit {
                                            font-size: 24px;
                                            line-height: 28px;
                                            // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                            span {
                                                width:26px;
                                                height:26px;
                                                margin-left:5px;
                                            }
                                            // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                        }
                                    }
                                    &__name {
                                        width: 88%;
                                        .main {
                                            font-size: 13px;
                                        }
                                    }
                                    &__sku {
                                        font-size: 11px;
                                    }
                                    &__price {
                                        display: block;
                                        &--current {
                                            margin-top: 2px;
                                            font-size: 13px;
                                            line-height: 1.272727;
                                        }
                                        &--benefit {
                                            font-size: 13px;
                                            line-height: 1.5;
                                        }
                                    }
                                    &__image {
                                        width: 160px;
                                        position: absolute;
                                        bottom: auto;
                                        top: 32px;
                                        left: 192px;
                                        aspect-ratio: 1 / 1;
                                        & > img {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            position: absolute;

                                        }
                                        &::before {
                                            content: '';
                                            display: block;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            background: #1A180E;
                                            opacity: 0.05;
                                            z-index:3;
                                        }
                                        &::after {
                                            display: none;
                                        }
                                    }
                                }
                                & > a {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                            &:nth-child(2){
                                margin-bottom: 16px;
                                .item {
                                    &__image {
                                        // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                        .rank {
                                            background: url(/lg5-common/images/SLS/2024/icon_ranking_best_2.png) no-repeat 0 0 / cover;
                                        }
                                    }
                                }
                            }
                            &:nth-child(3){
                                .item {
                                    &__image {
                                        // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                        .rank {
                                            background: url(/lg5-common/images/SLS/2024/icon_ranking_best_3.png) no-repeat 0 0 / cover;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // S : BTOCSITE-62058 스마트홈 경험 솔루션
            // 스마트홈 배너
            .smarthome__banner {
                max-width: 1476px;
                margin: 0 auto 80px;
                padding: 0 48px;
                height:140px;
                .banner__link {
                    display: block;
                    position: relative;
                    height: 100%;
                    border-radius: 16px;
                    overflow: hidden;
                    // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                    &:focus {
                        outline-offset:1px;
                    }
                    // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                    .banner__image {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: auto;
                        height: 100%;
                        img {
                            height:100%;
                        }
                    }
                    .banner__text-wrap {
                        position: absolute;
                        top: 50%;
                        left:36px;
                        transform: translateY(-50%);
                        .banner__title {
                            font-size:24px;
                            line-height:32px;
                            font-weight:700;
                            letter-spacing:-0.48px;
                        }
                        .banner__text {
                            margin-top:4px;
                            font-size:16px;
                            line-height:32px;
                        }
                        &.text-white {
                            color:#fff;
                        }
                    }
                }
                @include mobile {
                    margin:-18px 0 32px;
                    padding:0 16px;
                    height:auto;
                    .banner__link {
                        border-radius:8px;
                        .banner__image {
                            position: static; 
                            transform: none;
                            height: 98px;
                            img {
                                width:100%;
                                height:auto;
                                border-radius:8px;
                            }
                            @media screen and (max-width: 360px){
                                height: auto;
                            }
                        }
                        .banner__text-wrap {
                            left:20px;
                            .banner__title {
                                font-size:14px;
                                line-height:normal;
                                letter-spacing:-0.28px;
                            }
                            .banner__text {
                                margin-top:2px;
                                font-size:12px;
                                line-height:18px;
                            }
                        }
                    }
                }
            }
            // E : BTOCSITE-62058 스마트홈 경험 솔루션
            // 전체 카테고리 퀵 메뉴
            .category-quick-menu {
                &.inner {
                    margin-top: 0;
                }
                &__list {
                    display: flex;
                    margin:-14px 0px 0;
                    padding:14px 0px 24px 0px;
                    max-width: 1408px;
                    overflow-x: auto;
                    white-space: nowrap;

                    &::-webkit-scrollbar {
                        height:16px;
                        background-color:transparent;
                        border-radius:7px;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius:7px;
                        background-color:rgba(0,0,0,1);
                        border-top:4px solid rgba(255,255,255,1);
                        border-bottom:4px solid rgba(255,255,255,1);
                    }
                    &::-webkit-scrollbar-track {
                        // margin:0 14px;
                        border-radius:7px;
                        background-color:rgba(0,0,0,0.2);
                        border-top:4px solid rgba(255,255,255,1);
                        border-bottom:4px solid rgba(255,255,255,1);
                    }
                }
                &__item {
                    width: 120px;
                    margin-right: 24px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &__link {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
                &__img{
                    margin: 0 16px;
                    width: 88px;
                    height: 88px;
                    border-radius: 50%;
                    overflow: hidden;
                    & > img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                    }
                }
                &__title {
                    margin-top: 12px;
                    width: 100%;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -2%;
                    text-align: center;
                }
                @include mobile {
                    margin:0 -16px -16px 0;
                    padding:0 16px 16px;

                    &__list {
                        padding-bottom: 30px;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                    &__item {
                        width: 72px;
                        margin-left: 0px;
                        margin-right: 8px;
                        &:last-child {
                            margin-right: 16px;
                        }
                    }
                    &__img{
                        margin: 0 4px;
                        width: 64px;
                        height: 64px;
                        border-radius: 50%;
                        overflow: hidden;
                        & > img {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &__title {
                        margin-top: 4px;
                        font-size: 12px;
                        line-height: 1.333333;
                        white-space: normal;
                        word-break: keep-all;
                    }
                }
            }

            // 카테고리별 행사/제품 유닛
            .caresolution-event {
                .section {
                    &__category {
                        position: relative;
                        display: flex;
                        width: 100%;
                        max-width: 1380px;
                        aspect-ratio: 1/ 0.4;
                        border-radius: 16px;
                        overflow: hidden;
                        & + .section__category {
                            margin-top: 24px;
                        }
                        .category-img {
                            width: 100%;
                            height: 100%;
                            & > img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .category {
                            &__header {
                                position: absolute;
                                top: 32px;
                                left: 32px;
                                padding: 18px 0 0 8px;
                                width: 270px;
                                margin-right: 44px;
                                .img-wrap {
                                    display: none;
                                }
                            }
                            &__tit {
                                margin-bottom: 8px;
                                width: 90%;
                                font-size: 32px;
                                font-weight: 700;
                                line-height: 1.448125;
                                word-break: keep-all;
                                color: #000;
                                &::after {
                                    content: '';
                                    display: inline-block;
                                    position: relative;
                                    bottom: 0;
                                    right: -4px;
                                    width: 24px;
                                    height: 24px;
                                    background: url(/lg5-common/images/SLS/2024/img_Right_black_24@2x.svg)no-repeat 0 0 / cover;
                                }
                                &.black::after {
                                    background: url(/lg5-common/images/SLS/2024/img_Right_black_24@2x.svg)no-repeat 0 0 / cover;
                                }
                                &.white::after {
                                    background: url(/lg5-common/images/icons/btn-arr-24x24-white.svg)no-repeat 0 0 / cover;
                                }
                            }
                            &__desc {
                                font-size: 20px;
                                line-height: 1.448;
                                color: #000;
                            }
                            &__list {
                                display: flex;
                                position: absolute;
                                top: 32px;
                                right: 32px;
                            }
                            &__item {
                                width: 318px;
                                margin-right: 24px;
                                &:last-child {
                                    margin-right: 0;
                                }
                                .item {
                                    &__link {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                        @include pc {
                                            outline-offset:1px;
                                        }
                                    }
                                    &__image {
                                        position: relative;
                                        display: block;
                                        width: 100%;
                                        aspect-ratio: 1 / 1;
                                        border-radius: 16px;
                                        background-color: #fff;
                                        overflow: hidden;
                                        & > img {
                                            display: block;
                                            width: 82.389937%;
                                            height: 82.389937%;
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                        }
                                        // S : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                        .badge.lge-only {
                                            top:24px;
                                            right:24px;
                                        }
                                        // E : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                        // S : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                        .middle-badge-list {
                                            position:absolute;
                                            top:20px;
                                            right:20px;
                                            display:flex;
                                            flex-direction:column;
                                            flex-wrap:wrap;
                                            z-index:1;  
                                            gap:6px;
                                            > span {
                                                position:unset;
                                                display:block;
                                                width:48px;
                                                height:48px;
                                                &.badge-lge-only {
                                                    background:url(/lg5-common/images/icons/badge_lge_only.svg) no-repeat 0 0 / 100%;    
                                                }
                                                &.badge-gift-card {
                                                    background:url(/lg5-common/images/icons/badge_gift_card.svg) no-repeat 0 0 / 100%;
                                                }
                                                &.badge-gift {
                                                    background:url(/lg5-common/images/icons/badge_gift.svg) no-repeat 0 0 / 100%;    
                                                }
                                                &.badge-discount {
                                                    background:url(/lg5-common/images/icons/badge_discount.svg) no-repeat 0 0 / 100%;        
                                                }
                                                &.badge-point {
                                                    background:url(/lg5-common/images/icons/badge_point.svg) no-repeat 0 0 / 100%;
                                                }
                                            }  
                                        }
                                        // E : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                    }
                                    &__info {
                                        margin-top: 20px;
                                    }
                                    &__name {
                                        font-size: 20px;
                                        line-height: 1.4;
                                        margin-bottom: 2px;
                                        font-weight: 700;
                                        .main-title {
                                            .main {
                                                font-size: 20px;
                                                line-height: 1.4;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 2;
                                                -webkit-box-orient: vertical;
                                            }
                                        }
                                        .sub-text-new {
                                            .sub {
                                                font-weight: 400;
                                                color: #333;
                                                &::before {
                                                    content: ' ·';
                                                    margin: 0 3px;
                                                    line-height: 18.2px;
                                                    font-weight: 400;
                                                    margin-left: 0;
                                                }
                                            }
                                            
                                        }
                                    }
                                    &__sku {
                                        font-size: 16px;
                                        line-height: 1.5;
                                        color: #666;
                                    }
                                    &__price {
                                        display: block;
                                        &--current {
                                            margin-top: 8px;
                                            font-size: 20px;
                                            line-height: 1.4;
                                        }
                                        &--benefit {
                                            font-size: 16px;
                                            line-height: 1.5;
                                            color: #EA1917;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1476px){
                    .section {
                        &__category {
                            // padding: 2.168022vw;
                            width: auto;
                            aspect-ratio: 1/0.5;
                            & + .section__category {
                                margin-top: 1.626016vw;
                            }
                            .category-img {
                                & > img {
                                    width: auto;
                                }
                            }
                            .category {
                                &__header {
                                    top: 2.168022vw;
                                    left: 2.168022vw;
                                    padding: 1.219512vw 0 0 0.542005vw;
                                    width: 18.292683vw;
                                    margin-right: 2.981030vw;
                                }
                                &__tit {
                                    margin-bottom: 0.542005vw;
                                    width: 90%;
                                    font-size: 32px;
                                    font-weight: 700;
                                    line-height: 1.448125;
                                    word-break: keep-all;
                                    &::after {
                                        content: '';
                                        display: inline-block;
                                        position: relative;
                                        bottom: 0;
                                        right: -4px;
                                        width: 24px;
                                        height: 24px;;
                                    }
                                }
                                &__desc {
                                    font-size: 20px;
                                    line-height: 1.448;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                                &__list {
                                    display: flex;
                                    top: 2.168022vw;
                                    right: 2.168022vw;
                                }
                                &__item {
                                    width: 21.544715vw;
                                    margin-right: 1.626016vw;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                    .item {
                                        &__link {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                        }
                                        &__image {
                                            position: relative;
                                            display: block;
                                            width: 100%;
                                            aspect-ratio: 1 / 1;
                                            border-radius: 16px;
                                            background-color: #fff;
                                            overflow: hidden;
                                            & > img {
                                                display: block;
                                                width: 82.389937%;
                                                height: 82.389937%;
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                            }
                                        }
                                        &__info {
                                            margin-top: 1.355014vw;
                                        }
                                        &__name {
                                            font-size: 20px;
                                            line-height: 1.4;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                        }
                                        &__sku {
                                            font-size: 16px;
                                            line-height: 1.5;
                                            color: #666;
                                        }
                                        &__price {
                                            display: block;
                                            &--current {
                                                margin-top: 0.542005vw;
                                                font-size: 20px;
                                                line-height: 1.4;
                                            }
                                            &--benefit {
                                                font-size: 16px;
                                                line-height: 1.5;
                                                color: #EA1917;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1280px){
                    .section {
                        &__category {
                            width: auto;
                            height: 474px;
                            aspect-ratio:auto;
                            & + .section__category {
                                margin-top: 1.626016vw;
                            }
                            .category {
                                &__header {
                                    top: 32px;
                                    left: 32px;
                                    padding: 0;
                                    max-width: 262px;
                                    width: fit-content;
                                    margin-right: 5.156250vw;
                                }
                                &__tit {
                                    margin-bottom: 0.542005vw;
                                    width: 90%;
                                    font-size: 32px;
                                    font-weight: 700;
                                    line-height: 1.448125;
                                    word-break: keep-all;
                                    &::after {
                                        content: '';
                                        display: inline-block;
                                        position: relative;
                                        bottom: 0;
                                        right: -4px;
                                        width: 24px;
                                        height: 24px;;
                                    }
                                }
                                &__desc {
                                    font-size: 20px;
                                    line-height: 1.448;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                                &__list {
                                    display: flex;
                                    flex-direction: column;
                                    top: 21px;
                                    right: auto;
                                    left: 360px;
                                }
                                &__item {
                                    width: auto;
                                    margin-right: 0;
                                    margin-top: 12px;
                                    &:first-child {
                                        margin-top: 0;
                                    }
                                    .item {
                                        &__link {
                                            display: flex;
                                            align-items: start;
                                            width: 100%;
                                            height: 100%;
                                        }
                                        &__image {
                                            position: relative;
                                            display: block;
                                            margin-right: 19px;
                                            width: 136px;
                                            aspect-ratio: 1 / 1;
                                            border-radius: 8px;
                                            background-color: #fff;
                                            overflow: hidden;
                                            & > img {
                                                display: block;
                                                width: 82.389937%;
                                                height: 82.389937%;
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                            }
                                            // S : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                            .badge.lge-only {
                                                top:12px;
                                                right:12px;
                                                width:32px;
                                                height:32px;
                                            }
                                            // E : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                            // S : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                            .middle-badge-list {
                                                top:10px;
                                                right:6px;
                                                gap:2px;
                                                > span {
                                                    width:38px;
                                                    height:38px;
                                                }
                                            }
                                            // E : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                        }
                                        &__info {
                                            margin-top: 0;
                                            width: 300px;
                                        }
                                        &__name {
                                            margin-bottom: 2px;
                                            font-size: 20px;
                                            line-height: 1.4;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                        }
                                        &__sku {
                                            font-size: 16px;
                                            line-height: 1.5;
                                            color: #666;
                                        }
                                        &__price {
                                            display: block;
                                            &--current {
                                                margin-top: 2px;
                                                font-size: 20px;
                                                line-height: 1.4;
                                            }
                                            &--benefit {
                                                font-size: 16px;
                                                line-height: 1.5;
                                                color: #EA1917;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 950px){
                    .section {
                        &__category {
                            .category {
                                &__header {
                                    width: 26.580796%;
                                }
                                &__list {
                                    left: 36.299765%;
                                }
                            }
                        }
                    }
                }
                @include mobile {
                    margin-top: 0;
                    .section {
                        &__category {
                            padding: 0;
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            height: auto;
                            // max-width: 1380px;
                            aspect-ratio: auto;
                            border-radius: 8px;
                            // overflow: hidden;
                            .category-img {
                                display: none;
                            }
                            & + .section__category {
                                margin-top: 50px;
                            }
                            .category {
                                &__header {
                                    position: relative;
                                    padding: 0;
                                    max-width: none;
                                    width: 100%;
                                    aspect-ratio: auto;
                                    margin-right: 0;
                                    top: 8px;
                                    left: 8px;

                                    .img-wrap {
                                        display: block;
                                        position: relative;
                                        top: -8px;
                                        left: -8px;
                                        width: 100%;
                                        height: 90px;
                                        border-radius: 8px;
                                        overflow: hidden;
                                        & > img {
                                            display: block;
                                            width: 100%;
                                            height: auto;
                                        }
                                        @media screen and (max-width: 360px){
                                            height: auto;
                                        }
                                    }

                                    & > a {
                                        position: absolute;
                                        top: -8px;
                                        left: -8px;
                                        padding: 0 24px;
                                        display: block;
                                        border-radius: 8px;
                                        width: 100%;
                                        height: 100%;
                                        color: #fff;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                    }
                                }
                                &__tit {
                                    margin-bottom: 0;
                                    font-size: 18px;
                                    line-height: 1.447;
                                    color: #000;
                                    &::after {
                                        bottom: -1px;
                                        width: 16px;
                                        height: 16px;
                                        background: url(/lg5-common/images/SLS/2024/img_Right_black_24@2x.svg)no-repeat 0 0 / cover;
                                    }
                                }
                                &__desc {
                                    font-size: 12px;
                                    line-height: 1.448;
                                    color: #000;
                                    & br {
                                        display: none;
                                    }
                                }
                                &__list {
                                    position: relative;
                                    display: flex;
                                    flex-direction: row;
                                    margin-top: 8px;
                                    width: 100%;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                }
                                &__item {
                                    width: calc((100% - 10px) / 3);
                                    margin-right: 5px;
                                    margin-top: 0;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                    .item {
                                        &__link {
                                            flex-direction: column;
                                        }
                                        &__image {
                                            border-radius: 8px;
                                            width: 100%;
                                            margin-right: 0;
                                            & > img {
                                                width: 92.45283%;
                                                height: 92.45283%;
                                            }
                                            &::after {
                                                content: '';
                                                display: block;
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                background-color: #1A180E;
                                                opacity: 0.05;
                                            }
                                            // S : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                            .middle-badge-list {
                                                // BTOCSITE-80456 메인홈, 구독홈 엠블럼(Image label) 오류 노출 건
                                                top: 8px;
                                                right: 8px;
                                                > span {
                                                    width: 32px;
                                                    height: 32px;
                                                }
                                            }
                                            // E : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                        }
                                        &__info {
                                            margin-top: 8px;
                                            width: 100%;
                                        }
                                        &__name {
                                            font-size: 12px;
                                            .main-title {
                                                .main {
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                        &__sku {
                                            font-size: 11px;
                                        }
                                        &__price {
                                            display: block;
                                            &--current {
                                                margin-top: 8px;
                                                font-size: 12px;
                                                line-height: 1.4;
                                            }
                                            &--benefit {
                                                margin-top: 2px;
                                                font-size: 11px;
                                                line-height: 1.448181;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // UP 가전 2.0 소개/카테고리+제품 유닛
            .up-2,
            .caresolution-benefit {
                padding: 0px 48px;
                background: #f6f6f6; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                .section {
                    position:relative;
                    margin: 0 auto;
                    max-width: 1380px;
                    &__header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 32px;
                    }
                    &__img {
                        display: none;
                    }
                    &__link {
                        color: #666;
                        &::after {
                            content: '';
                            display: inline-block;
                            position: relative;
                            top: 2px;
                            right: 0;
                            width: 16px;
                            height: 16px;
                            margin-left: 2px;
                            background: url(/lg5-common/images/icons/btn-arr-16-black.svg)no-repeat 0 0 / cover;
                        }
                    }
                    &__content {
                        .scroll-wrap {
                            &.border-type {
                                margin:-3px;
                                margin-bottom:25px;
                                @include mobile {
                                    margin:-4px 0 21px -3px
                                }
                                .tabs {
                                    margin: 0;
                                    padding: 3px 16px 3px 3px;
                                    @include mobile {
                                        padding:4px 14px 4px 3px;
                                    }
                                    & > li {
                                        margin-left: 8px;
                                        &:first-child {
                                            margin-left: 0;
                                        }
                                        & > a {
                                            display: block;
                                            position: relative;
                                            padding: 7px 24px;
                                            border: 1px solid #8f8f8f; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                            border-radius: 32px;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 1.5;
                                            color: #000;
                                        }
                                        &.on {
                                            & > a {
                                                font-weight: 700;
                                                color: #fff;
                                                background-color: #000;
                                                &:focus-visible {
                                                    outline:3px dotted #000;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .btn-arrow {
                                &::before {
                                    position: absolute;
                                    top: 0;
                                    width: 60px;
                                    height: 48px;
                                    border-radius: 0;
                                    box-shadow: none;
                                }
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    width: 40px;
                                    height: 40px;  
                                    background: url(/lg5-common/images/SLS/2024/btn_circle_arr.png) center no-repeat;
                                    background-size: 100% 100%;
                                }
                                &.prev {
                                    left: 0;
                                    &::before {
                                        left: 0;
                                        background-image: linear-gradient(to right,#EFEFED 75%,rgba(244,244,244,0));
                                    }
                                    &::after {
                                        left: 0;
                                        transform: translateY(-50%) rotate(180deg);
                                    }
                                }
                                &.next {
                                    right: 0;
                                    &::before {
                                        right: 0;
                                        background-image: linear-gradient(to left,#EFEFED 75%,rgba(244,244,244,0));
                                    }
                                    &::after {
                                        right: 0;
                                        transform: translateY(-50%);
                                    }
                                }
                            }
                        }
                    }
                    &__btn-link {
                        display: none;
                    }
                }
            }
            .up-2 {
                position: relative;
                padding: 60px 48px 54px;

                .scroll-wrap {
                    margin-bottom: 22px;
                }
                .up-cont {
                    width: max-content;
                    // overflow: hidden;
                }
                .up-list {
                    display: flex;

                    &::-webkit-scrollbar {
                        height:16px;
                        background-color:transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius:7px;
                        background-color:rgba(0,0,0,1);
                        border-top:4px solid #EFEFED;
                        border-bottom:4px solid #EFEFED;
                    }
                    &::-webkit-scrollbar-track {
                        margin:0 14px;
                        border-radius:7px;
                        background-color:rgba(0,0,0,0.2);
                        border-top:4px solid #EFEFED;
                        border-bottom:4px solid #EFEFED;
                    }
                    & > li {
                        margin-left: 24px;
                        &:first-child {
                            margin-left: 0;
                        }
                        & > a {
                            display: flex;
                            padding: 24px;
                            width: 590px;
                            aspect-ratio: 1 / 0.488135;
                            background: #fff;
                            border-radius: 16px;
                            .thumb {
                                position:relative; // BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                width: 240px;
                                height: 240px;
                                & > img {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                }
                                // S : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                .badge.lge-only {
                                    top:-7px;
                                    right:-7px;
                                    @include mobile {
                                        right:0;
                                        width:32px;
                                        height:32px;
                                    }
                                }
                                // E : BTOCSITE-68167 [전시] 가전구독 제품 닷컴온리 뱃지 노출요청
                                // S : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                                .middle-badge-list {
                                    position:absolute;
                                    top:0;
                                    right:0;
                                    display:flex;
                                    flex-direction:column;
                                    flex-wrap:wrap;
                                    z-index:1;  
                                    gap:6px;
                                    > span {
                                        position:unset;
                                        display:block;
                                        width:48px;
                                        height:48px;
                                        &.badge-lge-only {
                                            background:url(/lg5-common/images/icons/badge_lge_only.svg) no-repeat 0 0 / 100%;    
                                        }
                                        &.badge-gift-card {
                                            background:url(/lg5-common/images/icons/badge_gift_card.svg) no-repeat 0 0 / 100%;
                                        }
                                        &.badge-gift {
                                            background:url(/lg5-common/images/icons/badge_gift.svg) no-repeat 0 0 / 100%;    
                                        }
                                        &.badge-discount {
                                            background:url(/lg5-common/images/icons/badge_discount.svg) no-repeat 0 0 / 100%;        
                                        }
                                        &.badge-point {
                                            background:url(/lg5-common/images/icons/badge_point.svg) no-repeat 0 0 / 100%;
                                        }
                                    }  
                                    @include mobile {
                                        top:-3px;   // BTOCSITE-80456 메인홈, 구독홈 엠블럼(Image label) 오류 노출 건
                                        gap:2px;
                                        > span {
                                            // BTOCSITE-80456 메인홈, 구독홈 엠블럼(Image label) 오류 노출 건                                            
                                            width:32px;
                                            height:32px;
                                        }
                                    }
                                }
                                // E : BTOCSITE-69466 [전시] 구독 프로모션 앰블럼 노출 요청
                            }
                            .context {
                                margin-left: 28px;
                                padding: 58px 2px 58px 0;
                                width: 272px;
                                .title {
                                    white-space: normal;
                                    font-size: 20px;
                                    line-height: 1.4;
                                    word-break: keep-all;
                                    font-weight: 700;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    .sub-text-new {
                                        .sub {
                                            font-weight: 400;
                                            color: #333;
                                            &::before {
                                                content: ' ·';
                                                margin: 0 3px;
                                                line-height: 18.2px;
                                                font-weight: 400;
                                                margin-left: 0;
                                            }
                                        }
                                        
                                    }
                                }
                                .colorchip-list {
                                    margin: 8px 0 16px;
                                    display: inline-flex;
                                    align-items: center;
                                    vertical-align: middle;
                                    flex-wrap: wrap;
                                    .chk-wrap-colorchip {
                                        position: relative;
                                        display: inline-block;
                                        input {
                                            display: none;
                                            cursor: pointer;
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                            border: none;
                                            background: 0 0;
                                        }
                                        label {
                                            margin-left: 4px;
                                            display: block;
                                            width: 16px;
                                            height: 16px;
                                            padding: 0;
                                            font-size: 0;
                                            line-height: 0;
                                            cursor: default;
                                            &::before {
                                                content: '';
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                border: 1px solid #ddd;
                                                border-radius: 50%;
                                                width: 16px;
                                                height: 16px;
                                            }
                                            &::after {
                                                display: none;
                                            }
                                        }
                                        input+label {
                                            position: relative;
                                            vertical-align: top;
                                            -webkit-user-select: none;
                                        }
                                        input:disabled+label {
                                            color: #aaa;
                                        }
                                        &.nature-green {
                                            input+label:before {
                                                background: #4C5351;
                                            }
                                        }
                                        &.nature-beige {
                                            input+label:before {
                                                background: #DBD2CA;
                                            }
                                        }
                                        &.washing_lily-white {
                                            input+label:before {
                                                background: #F9FBFA;
                                            }
                                        }
                                        &:first-child {
                                            label {
                                                margin-left: 0;
                                            }
                                        }
                                    }
                                }
                                .price {
                                    font-size: 20px;
                                    line-height: 1.4;
                                }
                            }
                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
                .section-inner {
                    position:relative; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                    margin: 0 auto;
                    max-width: 1380px;
                    .section-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 32px;
                    }
                    .section-content {
                        .up2-product {
                            margin-bottom: 22px;
                            position: relative;
                            .tabs-wrap-up2 {
                                position: relative;
                                display: block;
                                max-width: 1380px;
                                margin: -3px auto;
                                margin-bottom: 19px;
                                .tabs{
                                    padding: 3px 16px 3px 3px;
                                    font-size: 0;
                                    white-space: nowrap;
                                    vertical-align: top;
                                    & > li {
                                        margin-left: 8px;
                                        display: inline-block;
                                        white-space: nowrap;
                                        vertical-align: top;
                                        &:first-child {
                                            margin-left: 0;
                                        }
                                        & > a {
                                            display: block;
                                            position: relative;
                                            padding: 7px 24px;
                                            border: 1px solid #8f8f8f; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                            border-radius: 32px;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 1.5;
                                            color: #000;
                                        }
                                        &.on > a {
                                            font-weight: 700;
                                            color: #fff;
                                            background-color: #000;
                                            &:focus-visible {
                                                outline:3px dotted #000;
                                            }
                                        }
                                    }
                                }
                                .arrow-btn {
                                    .contols {
                                        button {
                                            display: block;
                                            width: 48px;
                                            height: 48px;
                                            padding: 4px;
                                            border-radius: 50%;
                                            font-size: 0;
                                            position: absolute;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                            &.prev {
                                                left: 0;
                                                &::before {
                                                    left: 0;
                                                    background-image: linear-gradient(90deg, #F6F6F6 56.38%, rgba(246, 246, 246, 0.84) 78.98%, rgba(246, 246, 246, 0.00) 100%);
                                                }
                                                &::after {
                                                    left: 0;
                                                    transform: translateY(-50%) rotate(180deg);
                                                }
                                            }
                                            &.next {
                                                right: 0;
                                                &::before {
                                                    right: 0;
                                                    background-image: linear-gradient(270deg, #F6F6F6 56.38%, rgba(246, 246, 246, 0.84) 78.98%, rgba(246, 246, 246, 0.00) 100%);
                                                }
                                                &::after {
                                                    right: 0;
                                                    transform: translateY(-50%);
                                                }
                                            }
                                            &::before {
                                                content: '';
                                                display: block;
                                                position: absolute;
                                                top: 0;
                                                width: 123px;
                                                height: 48px;
                                                border-radius: 0;
                                                box-shadow: none;
                                                background-repeat: no-repeat;
                                                background-size: 100%;
                                            }
                                            // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                            &::after {
                                                content: '';
                                                position: absolute;
                                                top: 50%;
                                                width: 40px;
                                                height: 40px;
                                                background: url(/lg5-common/images/SLS/2024/btn_circle_arr.png) center no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
                                    }
                                }
                                @include mobile {
                                    margin-bottom: 16px;
                                }
                            }
                        }
                        .up2-prd-cont {
                            max-width: none;
                            .up-list {
                                max-width: none;
                                overflow-x: visible;
                            }
                        }
                    }
                    @include mobile {
                        .section-header {
                            margin-bottom: 70px;
                            padding-left: 8px;
                        }
                        .section-content {
                            .up2-product {
                                margin: -4px 0 12px -3px;
                                .tabs-wrap-up2 {
                                    .tabs{
                                        padding: 4px 16px 4px 3px;
                                        font-size: 0;
                                        white-space: nowrap;
                                        vertical-align: top;
                                        & > li {
                                            margin-left: 6px;
                                            // display: inline-block;
                                            // white-space: nowrap;
                                            // vertical-align: top;
                                            // &:first-child {
                                            //     margin-left: 0;
                                            // }
                                            & > a {
                                                padding: 6.5px 12px;
                                                border: 1px solid #8f8f8f; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                                border-radius: 32px;
                                                font-weight: 400;
                                                font-size: 12px;
                                                line-height: 1.583333;
                                                color: #000;
                                                background: #fff;
                                            }
                                        }
                                    }
                                    .arrow-btn {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .section {
                    // S : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                    &__link-wrap {
                        position:absolute;
                        top:24px;
                        right:0;
                    }
                    // E : BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                    &__link {
                        color: #666;
                        &::after {
                            content: '';
                            display: inline-block;
                            position: relative;
                            top: 2px;
                            right: 0;
                            width: 16px;
                            height: 16px;
                            margin-left: 2px;
                            background: url(/lg5-common/images/icons/btn-arr-16-black.svg) no-repeat 0 0/cover;
                        }
                    }
                }
                .swiper-3d {
                    perspective: 1200px;
                    .swiper-wrapper {
                        transform-style: preserve-3d;
                    }
                    .swiper-slide {
                        transform-style: preserve-3d;
                    }
                }
                .swiper-pointer-events {
                    touch-action: pan-y;
                }
                .swiper {
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;
                    overflow: hidden;
                    list-style: none;
                    padding: 0;
                    z-index: 1;
                    @include mobile {
                        padding-right: 16px;
                    }
                }
                .swiper-wrapper {
                    transform: translate3d(0, 0, 0);
                    position: relative;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    display: flex;
                    transition-property: transform;
                    box-sizing: content-box;
                    .swiper-slide {
                        width: fit-content;
                    }
                }
                .swiper-creative {
                    .swiper-slide {
                        backface-visibility: hidden;
                        overflow: hidden;
                        transition-property: transform, opacity, height;
                        width: fit-content !important;
                        flex-shrink: 0;
                        height: 100%;
                        position: relative;
                        transition-property: transform;
                    }
                }

                @include mobile {
                    margin-top: 50px;
                    padding: 44px 0 20px 16px;
                    background: #EDEDEB;
                    .scroll-wrap {
                        margin-bottom: 16px;
                    }
                    .section {
                        &__header {
                            margin-bottom: 70px;
                            padding-left: 8px;
                        }
                        &__img {
                            display: block;
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            width: 160px;
                            height: 160px;
                            & > img { 
                                display: none;
                                width: 100%;
                                height: 100%;
                                &.on {display: block;}
                            }
                        }
                        &__title {
                            font-size: 20px;
                            line-height: 1.35;
                            .desc {
                                font-size: 12px;
                                line-height: 1.448333;
                                font-weight: 400;
                            }
                        }
                        &__link-wrap {
                            display: none;
                        }
                        &__content {
                            .scroll-wrap {
                                &.border-type {
                                    .tabs {
                                        & > li {
                                            margin-left: 6px;
                                            & > a {
                                                // display: block;
                                                // position: relative;
                                                padding: 6.5px 12px;
                                                border: 1px solid #ddd;
                                                border-radius: 32px;
                                                font-weight: 400;
                                                font-size: 12px;
                                                line-height: 1.583333;
                                                color: #000;
                                                background: #fff;
                                            }
                                        }
                                    }
                                }
                                .scroll-controls.ui_smooth_controls {
                                    display: none !important;
                                    // .btn-arrow {
                                    //     display: none;
                                    // }
                                    // button {
                                    //     display: none;
                                    // }
                                }
                            }
                        }
                        &__btn-link-wrap {
                            padding-right: 16px;
                        }
                        &__btn-link {
                            display: block;
                            padding: 13.5px 0 13.5px;
                            width: 100%;
                            border: 1px solid #8f8f8f; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                            font-size: 12px;
                            line-height: 1.583333;
                            border-radius: 8px;
                            text-align: center;
                            &::after {
                                content: '';
                                display: inline-block;
                                position: relative;
                                top: 2px;
                                right: -6px;
                                // margin-left: 1.666667vw;
                                width: 12px;
                                height: 12px;
                                background: url(/lg5-common/images/icons/btn-arr-16-black.svg)no-repeat 0 0 / cover;
                            }
                        }
                    }
                    .up-list {
                        padding-bottom: 0;
                        & > li {
                            margin-left: 16px;
                            & > a {
                                align-items: center;
                                width: 290px;
                                height: 115.45px;
                                margin-left: 16px;
                                padding: 16px 16px 14px 12px;
                                border-radius: 8px;
                                aspect-ratio: auto;
                                .thumb {
                                    width: 84px;
                                    height: 84px;
                                }
                                .context {
                                    margin-left: 8px;
                                    padding: 0;
                                    width: 170px;
                                    .title {
                                        // white-space: normal;
                                        width: 100%;
                                        // aspect-ratio: 1 / 0.211764;
                                        aspect-ratio: auto;
                                        font-size: 14px;
                                        line-height: 1.4;
                                        word-break: break-all;
                                        word-break: keep-all;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                    }
                                    .colorchip-list {
                                        margin: 12px 0 4px;
                                        .chk-wrap-colorchip {
                                            label {
                                                width: 10px;
                                                height: 10px;
                                                &::before {
                                                    width: 10px;
                                                    height: 10px;
                                                }
                                            }
                                        }
                                    }
                                    .price {
                                        font-size: 14px;
                                        line-height: 1.447857;
                                    }
                                }
                            }
                            &:first-child {
                                margin-left: 0;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }
            }

            // 혜택 안내
            .caresolution-benefit {
                margin-top: 80px;
                background: none;
                .section {
                    > .section__link-wrap {
                        @include pc {
                            position:absolute;
                            top:6px;
                            right:0;
                        }
                    }
                }
                .scroll-wrap {
                    margin-bottom: 28px;
                }
                .benefit-cont {
                    max-width: 1380px;
                    overflow: hidden;
                    margin:-2px -2px 0 -2px; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                }
                .benefit-list {
                    display: flex;
                    gap: 24px;
                    padding:2px 2px 24px 2px; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                    max-width: 1408px;
                    overflow-x: auto;
                    white-space: nowrap;
                    // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                    &:focus {
                        outline-offset:-1px;
                    }

                    &::-webkit-scrollbar {
                        height:16px;
                        background-color:transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius:7px;
                        background-color:rgba(0,0,0,1);
                        border-top:4px solid rgba(255,255,255,1);
                        border-bottom:4px solid rgba(255,255,255,1);
                    }
                    &::-webkit-scrollbar-track {
                        margin:0;
                        border-radius:7px;
                        background-color:rgba(0,0,0,0.2);
                        border-top:4px solid rgba(255,255,255,1);
                        border-bottom:4px solid rgba(255,255,255,1);
                    }
                    & > li {
                        padding: 20px;
                        display: flex;
                        gap: 24px;
                        width: 638px;
                        aspect-ratio: 1 / 0.351097;
                        border-radius: 16px;
                        position: relative;
                        .icon-wrap {
                            position: absolute;
                            top: 24px;
                            left: 24px;
                            width: 40px;
                            height: 40px;
                            & > img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .context {
                            position: relative;
                            padding: 56px 0 0 4px;
                            width: 310px;
                            white-space: normal;
                            .tit {
                                display: inline-block;
                                margin-bottom: 8px;
                                font-size: 20px;
                                line-height: 1.4;
                                letter-spacing: -0.4px;
                            }
                            .desc {
                                font-size: 16px;
                                line-height: 1.448125;
                                letter-spacing: -0.3px;
                            }
                        }
                        .thumb {
                            width: 260px;
                            height: 184px;
                            border-radius: 8px;
                            overflow: hidden;
                            & > img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        &:first-child {
                            background: #DED9C7;
                        }
                        &:nth-child(2) {
                            background: #DCD4CA;
                        }
                        &:nth-child(3) {
                            background: #E4E2E2;
                        }
                        &:nth-child(4) {
                            background: #DCD4CA;
                        }
                    }
                }
                @include mobile {
                    margin: 50px 0;
                    padding: 0 0 0 16px;
                    .section {
                        &__header {
                            margin-bottom: 16px;
                        }
                        &__content {
                            .scroll-wrap {
                                &.border-type {
                                    .tabs {
                                        & > li {
                                            margin-left: 6px;
                                            & > a {
                                                padding: 6.5px 12px;
                                                border: 1px solid #8f8f8f; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                                                border-radius: 32px;
                                                font-weight: 400;
                                                font-size: 12px;
                                                line-height: 1.583333;
                                                color: #000;
                                                background: #fff;
                                            }
                                        }
                                    }
                                }
                                .scroll-controls.ui_smooth_controls {
                                    display: none !important;
                                    .btn-arrow {
                                        display: none;
                                    }
                                    button {
                                        display: none;
                                    }
                                }
                            }
                        }
                        &__btn-link-wrap {
                            padding-right: 16px;
                        }
                        &__btn-link {
                            display: block;
                            padding: 14.5px 0 14.5px;
                            width: 100%;
                            border: 1px solid rgba(0,0,0,0.2);
                            font-size: 12px;
                            line-height: 1.583333;
                            border-radius: 8px;
                            text-align: center;
                            &::after {
                                content: '';
                                display: inline-block;
                                position: relative;
                                top: 2px;
                                right: -4px;
                                width: 12px;
                                height: 12px;
                                background: url(/lg5-common/images/icons/btn-arr-16-black.svg)no-repeat 0 0 / cover;
                            }
                        }
                        &__link-wrap {
                            display: none;
                        }
                    }
                    .scroll-wrap {
                        margin-bottom: 16px;
                    }
                    .benefit-list {
                        display: flex;
                        gap: 16px;
                        padding-bottom: 16px;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        & > li {
                            padding: 72px 16px 20px;
                            display: flex;
                            flex-direction: column;
                            gap: 16px;
                            width: 290px;
                            aspect-ratio: auto;
                            border-radius: 8px;
                            .icon-wrap {
                                top: 20px;
                                left: 20px;
                            }
                            .context {
                                padding: 0 0 0 4px;
                                width: 250px;
                                .tit {
                                    display: inline-block;
                                    margin-bottom: 8px;
                                    font-size: 14px;
                                    line-height: 1.447857;
                                }
                                .desc {
                                    height: 52.13px;
                                    font-size: 12px;
                                    line-height: 1.448333;
                                }
                                &::before {
                                    width: 40px;
                                    height: 40px;
                                }
                            }
                            .thumb {
                                width: 258px;
                                height: 150px;
                                border-radius: 6px;
                            }
                            &:first-child {
                                background: #DED9C7;
                                .context {
                                    &::before {
                                        background: url(/lg5-common/images/SLS/2024/icon_benefits_01.png)no-repeat 0 0 / cover;
                                    }
                                }
                            }
                            &:nth-child(2) {
                                background: #DCD4CA;
                                .context {
                                    &::before {
                                        background: url(/lg5-common/images/SLS/2024/icon_benefits_02.png)no-repeat 0 0 / cover;
                                    }
                                }
                            }
                            &:nth-child(3) {
                                background: #E4E2E2;
                                .context {
                                    &::before {
                                        background: url(/lg5-common/images/SLS/2024/icon_benefits_02.png)no-repeat 0 0 / cover;
                                    }
                                }
                            }
                            &:nth-child(4) {
                                background: #DCD4CA;
                                .context {
                                    &::before {
                                        background: url(/lg5-common/images/SLS/2024/icon_benefits_02.png)no-repeat 0 0 / cover;
                                    }
                                }
                            }
                            &:last-child {
                                margin-right: 16px;
                            }
                        }
                    }
                }
                #tab-2-02 {
                    .benefit-list {
                        & > li {
                            background: #eae6dd;
                        }
                    }
                }
            }

            // 전문 상담 서비스 안내
            .care-banner-wrap {
                display: flex;
                align-items: center;
                gap: 24px;
                .box {
                    width: calc((100% - 24px) / 2);
                    aspect-ratio: 1 / 0.1888;
                    background: #f7f7f7; // BTOCSITE-77412 [웹앱 접근성 개선활동] 전시_가전구독
                    border-radius: 16px;
                    & > a {
                        display: block;
                        padding: 24px 50px 24px 40px;
                        width: 100%;
                        height: 100%; 
                        min-height: 128px;
                        .tit {
                            display: block;
                            position: relative;
                            margin-bottom: 4px;
                            font-size: 20px;
                            line-height: 1.48;
                            letter-spacing: -2%;
                        }
                        .desc {
                            display: block;
                            font-size: 16px;
                            line-height: 1.448125;
                            letter-spacing: -2%;
                        }
                    }
                    &.tel {
                        & > a {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer;
                            .tit {
                                .phone {
                                    display: none;
                                }
                            }
                            .phone-num {
                                font-size: 28px;
                                line-height: 1.48;
                            }
                            .desc-wrap {
                                .desc {
                                    display: inline-block;
                                    &.weekday.mo {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    &.inquiry {
                        & > a {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .tit {
                                br { 
                                    display: none;
                                }
                            }
                            &::after {
                                content: '';
                                display: inline-block;
                                width: 64px;
                                height: 64px;
                                background:url(/lg5-common/images/SLS/2024/img_CS_email_64.svg)no-repeat 0 0 /cover;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1160px){
                    gap: 2.068966vw;
                    .box {
                        width: calc((100% - 2.068966vw) / 2);
                        & > a {
                            display: block;
                            padding: 2.068966vw 4.310345vw 2.068966vw 3.448276vw;
                            width: 100%;
                            height: 100%; 
                            .tit {
                                margin-bottom: 0;
                            }
                        }
                        &.tel {
                            & > a {
                                .img-wrap {
                                    width: 50px;
                                    height: 50px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                .desc-wrap {
                                    .desc {
                                        display: block;
                                        & > em {
                                            display: none;
                                        }
                                        &.weekday.mo {
                                            display: block;
                                        }
                                        &.weekday.pc {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        &.inquiry {
                            & > a {
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .tit {
                                    br { 
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 900px){
                    .box {
                        aspect-ratio: auto;
                        border-radius: 16px;
                        & > a {
                            padding: 20px 16px 20px 40px;
                            min-height: 184px;
                            .text-wrap {
                                width: 100%;
                                min-height: 105px;
                            }
                            .tit {
                                display: block;
                                position: relative;
                                margin-bottom: 4px;
                                font-size: 20px;
                                line-height: 1.6;
                            }
                            .desc,.e-mail {
                                font-size: 16px;
                                line-height: 1.5;
                            }
                        }
                        &.tel {
                            & > a {
                                .tit {
                                    .phone {
                                        display: block;
                                        margin-top: 4px;
                                    }
                                }
                                .phone-num {
                                    display: none;
                                }
                            }
                        }
                        &.inquiry {
                            & > a {
                                position: relative;
                                display: flex;
                                align-items: start;
                                justify-content: space-between;
                                .tit {
                                    &::after {
                                        content: '';
                                        display: inline-block;
                                        position: relative;
                                        top: 3px;
                                        right: -4px;
                                        width: 24px;
                                        height: 24px;
                                        background: url(/lg5-common/images/SLS/2024/img_CS_email_64_2.svg)no-repeat 0 0 / cover;
                                    }
                                    br { 
                                        display: block;
                                    }
                                }
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                @include mobile {
                    .box {
                        aspect-ratio: auto;
                        border-radius: 8px;
                        & > a {
                            padding: 20px 16px;
                            min-height: 128px;
                            .text-wrap {
                                width: 130px;
                                min-height: 105px;
                            }
                            .tit {
                                display: block;
                                position: relative;
                                margin-bottom: 8px;
                                font-size: 13px;
                                line-height: 1.6;
                            }
                            .desc,.e-mail {
                                font-size: 12px;
                                line-height: 1.5;
                            }
                        }
                        &.tel {
                            & > a {
                                .tit {
                                    .phone {
                                        display: inline-block;
                                    }
                                    &::after {
                                        content: '';
                                        display: inline-block;
                                        position: relative;
                                        bottom: -2px;
                                        right: 0;
                                        margin-left: 4px;
                                        width: 16px;
                                        height: 16px;
                                        background: url(/lg5-common/images/SLS/2024/icon_phon_76.png)no-repeat center / 85% auto;
                                    }

                                }
                                .phone-num {
                                    display: none;
                                }
                            }
                        }
                        &.inquiry {
                            & > a {
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .tit {
                                    br { 
                                        display: block;
                                    }
                                    &::after {
                                        content: '';
                                        display: inline-block;
                                        position: relative;
                                        bottom: -4px;
                                        right: 0;
                                        margin-left: 4px;
                                        width: 16px;
                                        height: 16px;
                                        background: url(/lg5-common/images/SLS/2024/icon_mail_32.png)no-repeat center / 100% auto;
                                    }
                                }
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // 개인화메세지
    .floating-wrap {
        // bottom: 104px;
    }
    .floating-message {
        position: fixed;
        right:48px;
        bottom: 48px;
        display: none;
        align-items: center;
        margin-top: 16px;
        padding: 0 12px;
        width: max-content;
        border-radius: 32px;
        background-color:  #0A1622;
        opacity: 0.9;
        z-index: 111;
        &.on {
            display: flex;
        }
        .message {
            font-size: 14px;
            line-height: 40px;
            color: #fff;
            font-weight: 400;
            letter-spacing: 0.5px;
            & > a {
                display: block;
                &::after {
                    content: '';
                    display: inline-block;
                    position: relative;
                    top: 1px;
                    margin-left: 4px;
                    width: 12px;
                    height: 12px;
                    background: url(/lg5-common/images/icons/btn-arr-24x24-white.svg)no-repeat 0 0 / cover;
                }
                & > em {
                    color: #FFD600;
                    font-weight: 700;
                }
            }
        }
        .close-btn {
            margin-left: 8px;
            width: 16px;
            height: 16px;
            background: url(/lg5-common/images/SLS/2024/ic_Messageba_close_32.png)no-repeat 0 0 / cover;
        }
        @include mobile {
            position: fixed;
            right:14px;
            bottom: 62px;
            display: none;
            align-items: center;
            margin-top: 10px;
            padding: 0 8px 0 0;
            width: calc(100% - 28px);
            border-radius: 32px;
            background-color:  #0A1622;
            opacity: 0.9;
            z-index: 110;
            &.on {
                display: flex;
                justify-content: space-between;
            }
            .message {
                width: calc(100% - 16px);
                font-size: 12px;
                line-height: 32px;
                & > a {
                    display: block;
                    text-align: center;
                    &::after {
                        content: '';
                        display: inline-block;
                        position: relative;
                        top: 1px;
                        margin-left: 4px;
                        width: 12px;
                        height: 12px;
                        background: url(/lg5-common/images/icons/btn-arr-24x24-white.svg)no-repeat 0 0 / cover;
                    }
                    & > em {
                        color: #FFD600;
                        font-weight: 700;
                    }
                }
            }
            .close-btn {
                margin-left: 0;
            }
        }
    }
} 